import { Action, createReducer, on } from '@ngrx/store'

import * as QUARTERLY_DASHBOARD_ACTIONS from './quarterly-dashboard.actions'
import { createQuarterlyDashboardInitState, IQuarterlyDashboardState } from './quarterly-dashboard.state'

export const quarterlyDashboardState = createReducer(
  createQuarterlyDashboardInitState(),

  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterList, state => ({ ...state, inRequest: true })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterListSuccess, (state, { quarterList }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      quarterList,
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterListError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: {
      ...state.data,
      error,
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.setQuarterlyDashboardQuarter, (state, { quarter }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      quarter,
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.setDefaultQuarterlyDashboardQuarter, (state, { quarter }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      defaultQuarter: quarter,
    },
  })),

  // MktCap
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCap, state => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardMktCap: {
        ...state.data.quarterlyDashboardMktCap,
        inRequest: true,
      },
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCapSuccess, (state, { data, quarter, order, sortBy }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      quarterlyDashboardMktCap: {
        inRequest: false,
        quarter,
        sortBy,
        order,
        data,
      },
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCapError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardMktCap: {
        inRequest: false,
        error,
      },
    },
  })),

  // Sector
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSector, state => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardSector: {
        ...state.data.quarterlyDashboardSector,
        inRequest: true,
      },
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorSuccess, (state, { data, quarter, order, sortBy }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      quarterlyDashboardSector: {
        inRequest: false,
        quarter,
        sortBy,
        order,
        data,
      },
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorError, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardSector: {
        inRequest: false,
        error,
      },
    },
  })),

  // Sector Top 500
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500, state => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardSectorTop500: {
        ...state.data.quarterlyDashboardSectorTop500,
        inRequest: true,
      },
    },
  })),
  on(
    QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500Success,
    (state, { data, quarter, order, sortBy }) => ({
      ...state,
      inRequest: false,
      inError: false,
      data: {
        ...state.data,
        quarterlyDashboardSectorTop500: {
          inRequest: false,
          quarter,
          sortBy,
          order,
          data,
        },
      },
    })
  ),
  on(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500Error, (state, { error }) => ({
    ...state,
    data: {
      ...state.data,
      quarterlyDashboardSectorTop500: {
        inRequest: false,
        error,
      },
    },
  })),
  on(QUARTERLY_DASHBOARD_ACTIONS.setQuarterlyDashboardViewMode, (state, { viewMode }) => ({
    ...state,
    data: { ...state.data, quarterlyDashboardViewMode: viewMode },
  }))
)

export const quarterlyDashboardReducer = (state: IQuarterlyDashboardState, action: Action): IQuarterlyDashboardState =>
  quarterlyDashboardState(state, action)
