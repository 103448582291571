import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { IForecastSearchPreviewDto } from '~/core/models'

import * as SEARCH_FORECAST_ACTIONS from './search-forecast.actions'
import {
  selectAllowSearchInCurrentFolder,
  selectForecastSearchOptions,
  selectSearchForecastCompanyTicker,
  selectSearchForecastError,
  selectSearchForecastInError,
  selectSearchForecastInRequest,
  selectSearchInCurrentFolder,
  selectSearchText,
  selectShouldRedirectToForecastTable,
} from './search-forecast.selectors'
import { IAppWithSearchForecastState } from './search-forecast.state'

export interface ISearchForecastFacade {
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  error$: Observable<Error | null | undefined>

  searchOptions$: Observable<IForecastSearchPreviewDto[]>
  searchText$: Observable<string>
  inCurrentFolder$: Observable<boolean>

  allowSearchInCurrentFolder$: Observable<boolean>
  shouldRedirectToForecastTable$: Observable<boolean>

  forecastCompanyTicker$: Observable<string>

  searchPreview(search: string): void
  searchForecasts(search: string): void
  chooseCompany(selectedTicker: string): void
  changeCurrentFolderOption(inCurrentFolder: boolean): void
  clearSearchCompanyPreview(): void
  resetSearch(): void
}

export const SEARCH_FORECAST_FACADE = new InjectionToken<ISearchForecastFacade>('SEARCH_FORECAST_FACADE')

@Injectable()
export class SearchForecastFacade implements ISearchForecastFacade {
  inRequest$ = this.store$.select(selectSearchForecastInRequest)
  inError$ = this.store$.select(selectSearchForecastInError)
  error$ = this.store$.select(selectSearchForecastError)

  searchOptions$ = this.store$.select(selectForecastSearchOptions)
  searchText$ = this.store$.select(selectSearchText)
  inCurrentFolder$ = this.store$.select(selectSearchInCurrentFolder)

  allowSearchInCurrentFolder$ = this.store$.select(selectAllowSearchInCurrentFolder)
  shouldRedirectToForecastTable$ = this.store$.select(selectShouldRedirectToForecastTable)

  forecastCompanyTicker$ = this.store$.select(selectSearchForecastCompanyTicker)

  constructor(private store$: Store<IAppWithSearchForecastState>) {}

  searchPreview(search: string): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.searchPreview({ search }))
  }

  searchForecasts(search: string): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.searchForecasts({ search }))
  }

  resetSearch(): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.resetSearch())
  }

  clearSearchCompanyPreview(): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.clearSearchCompanyPreview())
  }

  chooseCompany(selectedTicker: string): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.chooseCompany({ selectedTicker }))
  }

  changeCurrentFolderOption(inCurrentFolder: boolean): void {
    this.store$.dispatch(SEARCH_FORECAST_ACTIONS.changeCurrentFolderOption({ inCurrentFolder }))
  }
}
