import { createFeatureSelector, createSelector } from '@ngrx/store'

import { IQuarterlyDashboardState, quarterlyDashboardFeatureKey } from './quarterly-dashboard.state'

export const selectQuarterlyDashboard = createFeatureSelector<IQuarterlyDashboardState>(quarterlyDashboardFeatureKey)

export const selectQuarterlyDashboardInRequest = createSelector([selectQuarterlyDashboard], state => state.inRequest)

export const selectQuarterlyDashboardInError = createSelector([selectQuarterlyDashboard], state => state.inError)

export const selectQuarterlyDashboardData = createSelector([selectQuarterlyDashboard], state => state.data)

export const selectQuarterlyDashboardQuarterList = createSelector(
  [selectQuarterlyDashboardData],
  state => state.quarterList
)

export const selectQuarterlyDashboardQuarter = createSelector([selectQuarterlyDashboardData], state => state.quarter)

export const selectQuarterlyDashboardDefaultQuarter = createSelector(
  [selectQuarterlyDashboardData],
  state => state.defaultQuarter
)

export const selectQuarterlyDashboardError = createSelector([selectQuarterlyDashboardData], state => state.error)

// MktCap
export const selectQuarterlyDashboardMktCap = createSelector(
  [selectQuarterlyDashboardData],
  state => state.quarterlyDashboardMktCap
)

export const selectQuarterlyDashboardMktCapInRequest = createSelector(
  [selectQuarterlyDashboardMktCap],
  state => state?.inRequest
)

export const selectQuarterlyDashboardMktCapError = createSelector(
  [selectQuarterlyDashboardMktCap],
  state => state?.error
)

export const selectQuarterlyDashboardMktCapQuarter = createSelector(
  [selectQuarterlyDashboardMktCap],
  state => state?.quarter
)

export const selectQuarterlyDashboardMktCapData = createSelector([selectQuarterlyDashboardMktCap], state => state?.data)

export const selectQuarterlyDashboardMktCapSortBy = createSelector(
  [selectQuarterlyDashboardMktCap],
  state => state?.sortBy
)

export const selectQuarterlyDashboardMktCapOrder = createSelector(
  [selectQuarterlyDashboardMktCap],
  state => state?.order
)

// Sector
export const selectQuarterlyDashboardSector = createSelector(
  [selectQuarterlyDashboardData],
  state => state.quarterlyDashboardSector
)

export const selectQuarterlyDashboardSectorInRequest = createSelector(
  [selectQuarterlyDashboardSector],
  state => state?.inRequest
)

export const selectQuarterlyDashboardSectorError = createSelector(
  [selectQuarterlyDashboardSector],
  state => state?.error
)

export const selectQuarterlyDashboardSectorQuarter = createSelector(
  [selectQuarterlyDashboardSector],
  state => state?.quarter
)

export const selectQuarterlyDashboardSectorData = createSelector([selectQuarterlyDashboardSector], state => state?.data)

export const selectQuarterlyDashboardSectorSortBy = createSelector(
  [selectQuarterlyDashboardSector],
  state => state?.sortBy
)

export const selectQuarterlyDashboardSectorOrder = createSelector(
  [selectQuarterlyDashboardSector],
  state => state?.order
)

// Sector Top 500
export const selectQuarterlyDashboardSectorTop500 = createSelector(
  [selectQuarterlyDashboardData],
  state => state.quarterlyDashboardSectorTop500
)

export const selectQuarterlyDashboardSectorTop500InRequest = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.inRequest
)

export const selectQuarterlyDashboardSectorTop500Error = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.error
)

export const selectQuarterlyDashboardSectorTop500Quarter = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.quarter
)

export const selectQuarterlyDashboardSectorTop500Data = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.data
)

export const selectQuarterlyDashboardSectorTop500SortBy = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.sortBy
)

export const selectQuarterlyDashboardSectorTop500Order = createSelector(
  [selectQuarterlyDashboardSectorTop500],
  state => state?.order
)

// view mode
export const selectQuarterlyDashboardViewMode = createSelector(
  [selectQuarterlyDashboardData],
  state => state.quarterlyDashboardViewMode
)
