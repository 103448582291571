import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { ISnackBar } from '~/ui/snack-bar'

import * as UI_ACTIONS from './ui.actions'
import { ELanguage } from './ui.model'
import { selectInRequest, selectUiLanguage, selectUiThemeMode } from './ui.selectors'
import { IAppWithUiState } from './ui.state'

export interface IUiFacade {
  isDarkTheme$: Observable<boolean>
  inRequest$: Observable<boolean>
  language$: Observable<ELanguage>

  init(): void
  changeThemeMode(isDarkTheme: boolean): void
  changeLanguage(): void
  startRequest(): void
  endRequest(): void
  addNotification(snackBarData: ISnackBar): void
  addErrorNotification(messageI18n: string, messageKeyI18n?: { [key: string]: string }): void
  addInfoNotification(messageI18n: string, messageKeyI18n?: { [key: string]: string }): void
}

export const UI_FACADE = new InjectionToken<IUiFacade>('UI_FACADE')

@Injectable()
export class UiFacade implements IUiFacade {
  isDarkTheme$ = this.store$.select(selectUiThemeMode)
  inRequest$ = this.store$.select(selectInRequest)
  language$ = this.store$.select(selectUiLanguage)

  constructor(private store$: Store<IAppWithUiState>) {}

  init(): void {
    this.store$.dispatch(UI_ACTIONS.initUi())
  }

  changeThemeMode(isDarkTheme: boolean): void {
    this.store$.dispatch(UI_ACTIONS.changeThemeMode({ isDarkTheme }))
  }

  changeLanguage(): void {
    this.store$.dispatch(UI_ACTIONS.changeLanguage())
  }

  startRequest(): void {
    this.store$.dispatch(UI_ACTIONS.startRequest())
  }

  endRequest(): void {
    this.store$.dispatch(UI_ACTIONS.endRequest())
  }

  addNotification(snackBarData: ISnackBar): void {
    this.store$.dispatch(UI_ACTIONS.addNotification({ snackBarData }))
  }

  addErrorNotification(messageI18n: string, messageKeyI18n: { [key: string]: string } = {}): void {
    this.store$.dispatch(UI_ACTIONS.addErrorNotification({ messageI18n, messageKeyI18n }))
  }

  addInfoNotification(messageI18n: string, messageKeyI18n: { [key: string]: string } = {}): void {
    this.store$.dispatch(UI_ACTIONS.addInfoNotification({ messageI18n, messageKeyI18n }))
  }
}
