import { createFeatureSelector, createSelector } from '@ngrx/store'

import { EGuardSectionName } from '~/core/models'

import { GUARD_SECTION_DATA_NAME } from './router.model'
import { RouteReducerState, routerFeatureKey } from './router.state'

export const getRouterReducerState = createFeatureSelector<RouteReducerState>(routerFeatureKey)

export const getMergedRoute = createSelector(getRouterReducerState, routerReducerState => routerReducerState?.state)

export const selectData = createSelector([getMergedRoute], mergedRoute => mergedRoute?.data || {})

export const selectDataByKey = (key: string): any => createSelector([selectData], state => state[key])

// TODO: Refactor
export const selectGuardSectionName = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[GUARD_SECTION_DATA_NAME]
)

export const selectIsCompanyPage = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[GUARD_SECTION_DATA_NAME] === EGuardSectionName.COMPANY_PAGE
)
