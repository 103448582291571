import { Injectable, Optional } from '@angular/core'

import { AuthService } from '@auth0/auth0-angular'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'

import { GuardApiService, UserApiService } from '~/core/api'

import * as UI_ACTIONS from '../ui/ui.actions'

import * as USER_ACTIONS from './user.actions'

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userApiService: UserApiService,
    private guardApiService: GuardApiService,
    @Optional() private authService: AuthService | null
  ) {}

  initAuthUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.initAuthUser),
      switchMap(() => this.authService?.isAuthenticated$ || of(false)),
      mergeMap(isAuthenticated =>
        isAuthenticated
          ? this.userApiService.getUser().pipe(
              map(user => USER_ACTIONS.getAuthUserSuccess({ user })),
              catchError(error => [USER_ACTIONS.getAuthUserError({ error }), USER_ACTIONS.getGuestPermissions()])
            )
          : [USER_ACTIONS.getGuestPermissions()]
      )
    )
  )

  updateCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.updateCurrentUser),
      mergeMap(({ firstName, lastName, companyName }) =>
        this.userApiService.updateCurrentUser(firstName, lastName, companyName).pipe(
          map(user => USER_ACTIONS.updateCurrentUserSuccess({ user })),
          catchError(error => [
            USER_ACTIONS.updateCurrentUserError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  getAuthUserSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.getAuthUserSuccess),
      mergeMap(({ user }) => [
        USER_ACTIONS.getUserFolders(),
        USER_ACTIONS.saveUser({ user }),
        USER_ACTIONS.getPermissionsSuccess({
          sections: user?.permissions.sections,
          entities: user?.permissions.entities,
        }),
      ])
    )
  )

  getGuestPermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.getGuestPermissions),
      mergeMap(() =>
        this.guardApiService.getCurrentGuardPlanPermissions().pipe(
          map(({ sections, entities }) => USER_ACTIONS.getPermissionsSuccess({ sections, entities })),
          catchError(error => [USER_ACTIONS.getPermissionsError({ error })])
        )
      )
    )
  )

  getUserFolders$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.getUserFolders),
      mergeMap(() =>
        this.userApiService.getUserFolders().pipe(
          map(userFolders => USER_ACTIONS.getUserFoldersSuccess({ userFolders })),
          catchError(error => [
            USER_ACTIONS.getUserFoldersError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  logout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.logout),
      mergeMap(() => this.authService?.logout() || of(true)),
      map(() => USER_ACTIONS.saveUser({ user: null }))
    )
  )

  addUserFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.addUserFolder),
      mergeMap(({ folderName }) =>
        this.userApiService.addUserFolder(folderName).pipe(
          map(userFolders => USER_ACTIONS.addUserFolderSuccess({ userFolders })),
          catchError(error => [
            USER_ACTIONS.addUserFolderError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  editUserFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.editUserFolder),
      mergeMap(({ folderId, folderName }) =>
        this.userApiService.editUserFolder(folderId, folderName).pipe(
          map(userFolder => USER_ACTIONS.editUserFolderSuccess({ userFolder })),
          catchError(error => [
            USER_ACTIONS.editUserFolderError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  deleteUserFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.deleteUserFolder),
      mergeMap(({ folderId }) =>
        this.userApiService.deleteUserFolder(folderId).pipe(
          map(() => USER_ACTIONS.deleteUserFolderSuccess({ folderId })),
          catchError(error => [
            USER_ACTIONS.deleteUserFolderError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )

  moveForecastsToUserFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(USER_ACTIONS.moveForecastsToUserFolder),
      mergeMap(({ folderId, companyIds }) =>
        this.userApiService.moveForecastsToUserFolder(folderId, companyIds).pipe(
          map(() => USER_ACTIONS.moveForecastsToUserFolderSuccess()),
          catchError(error => [
            USER_ACTIONS.moveForecastsToUserFolderError({ error }),
            UI_ACTIONS.addErrorNotification(error?.message || ''),
          ])
        )
      )
    )
  )
}
