import { createFeatureSelector, createSelector } from '@ngrx/store'

import { EGuardSectionName } from '~/core/models'

import { GUARD_SECTION_DATA_NAME } from '../router'
import { getMergedRoute } from '../router/router.selectors'

import { ALLOW_SEARCH_IN_CURRENT_FOLDER_DATA_NAME } from './search-forecast.model'
import { ISearchForecastState, searchForecastFeatureKey } from './search-forecast.state'

export const selectSearchForecastState = createFeatureSelector<ISearchForecastState>(searchForecastFeatureKey)

export const selectSearchForecastInRequest = createSelector([selectSearchForecastState], state => state.inRequest)

export const selectSearchForecastInError = createSelector([selectSearchForecastState], state => state.inError)

export const selectSearchForecastData = createSelector([selectSearchForecastState], state => state.data)

export const selectForecastSearchOptions = createSelector(
  [selectSearchForecastData],
  state => state.searchCompanyPreview
)

export const selectSearchForecastError = createSelector([selectSearchForecastData], state => state.error)

export const selectSearchInCurrentFolder = createSelector([selectSearchForecastData], state => state.inCurrentFolder)

// TODO: Refactor
export const selectSearchText = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.queryParams['search'] || ''
)

// TODO: Refactor
export const selectSearchForecastCompanyTicker = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.queryParams['selectedTicker'] || ''
)

// TODO: Refactor
export const selectAllowSearchInCurrentFolder = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[ALLOW_SEARCH_IN_CURRENT_FOLDER_DATA_NAME] || false
)

// TODO: Refactor
export const selectShouldRedirectToForecastTable = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[GUARD_SECTION_DATA_NAME] !== EGuardSectionName.FORECAST_TABLE
)
