import { createAction, props } from '@ngrx/store'

import {
  EQuarterlyDashboardViewMode,
  IQuarterlyDashboardMktCap,
  IQuarterlyDashboardQuarter,
  IQuarterlyDashboardSector,
  IQuarterlyDashboardSectorTop500,
} from './quarterly-dashboard.model'
import { quarterlyDashboardFeatureKey } from './quarterly-dashboard.state'

export const getQuarterlyDashboardQuarterList = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Quarter List`
)
export const getQuarterlyDashboardQuarterListSuccess = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Quarter List Success`,
  props<{
    quarterList: IQuarterlyDashboardQuarter[]
  }>()
)
export const getQuarterlyDashboardQuarterListError = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Quarter List Error`,
  props<{ error: Error }>()
)
export const setQuarterlyDashboardQuarter = createAction(
  `[${quarterlyDashboardFeatureKey}] Set QuarterlyDashboard Quarter`,
  props<{
    quarter: IQuarterlyDashboardQuarter | null
  }>()
)

export const setDefaultQuarterlyDashboardQuarter = createAction(
  `[${quarterlyDashboardFeatureKey}] Set Default QuarterlyDashboard Quarter`,
  props<{
    quarter: IQuarterlyDashboardQuarter | null
  }>()
)

// MktCap
export const getQuarterlyDashboardMktCap = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard MktCap List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardMktCapSuccess = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard MktCap List Success`,
  props<{
    data: IQuarterlyDashboardMktCap[]
    quarter: IQuarterlyDashboardQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardMktCapError = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard MktCap List Error`,
  props<{ error: Error }>()
)

// Sector
export const getQuarterlyDashboardSector = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Sector List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardSectorSuccess = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Sector List Success`,
  props<{
    data: IQuarterlyDashboardSector[]
    quarter: IQuarterlyDashboardQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardSectorError = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard Sector List Error`,
  props<{ error: Error }>()
)

// Sector Top 500
export const getQuarterlyDashboardSectorTop500 = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard SectorTop500 List`,
  props<{
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardSectorTop500Success = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard SectorTop500 List Success`,
  props<{
    data: IQuarterlyDashboardSectorTop500[]
    quarter: IQuarterlyDashboardQuarter
    sortBy: string
    order: 'asc' | 'desc' | ''
  }>()
)
export const getQuarterlyDashboardSectorTop500Error = createAction(
  `[${quarterlyDashboardFeatureKey}] Get QuarterlyDashboard SectorTop500 List Error`,
  props<{ error: Error }>()
)

export const setQuarterlyDashboardViewMode = createAction(
  `[${quarterlyDashboardFeatureKey}] Set View Mode`,
  props<{ viewMode: EQuarterlyDashboardViewMode }>()
)
