import { Inject, Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, filter, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { CompanyApiService, ForecastApiService } from '~/core/api'

import { IRouterFacade, ROUTER_FACADE } from '../router'

import * as FORECAST_ACTIONS from './company.actions'
import { COMPANY_FACADE, ICompanyFacade } from './company.facade'

@Injectable()
export class ForecastListEffects {
  constructor(
    private actions$: Actions,
    private companyApiService: CompanyApiService,
    private forecastApiService: ForecastApiService,
    @Inject(ROUTER_FACADE) private routerFacade: IRouterFacade,
    @Inject(COMPANY_FACADE) private companyFacade: ICompanyFacade
  ) {}

  getForecast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getForecast),
      withLatestFrom(this.companyFacade.forecast$, this.routerFacade.isCompanyPage$),
      switchMap(([{ ticker }, forecast, isCompanyPage]) =>
        // (ticker && ticker === forecast?.company.ticker
        //   ? of(forecast)
        //   : this.forecastApiService.getForecast(isCompanyPage, ticker)
        // )

        this.forecastApiService.getForecast(isCompanyPage, ticker).pipe(
          mergeMap(forecast => [
            FORECAST_ACTIONS.getForecastSuccess({ forecast }),
            // FORECAST_ACTIONS.getForecastEdgar({ ticker: forecast.company.ticker }),
          ]),
          catchError(error => [FORECAST_ACTIONS.getForecastError({ ticker, error })])
        )
      )
    )
  )

  getForecastEdgar$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getForecastEdgar),
      withLatestFrom(this.routerFacade.isCompanyPage$),
      switchMap(([{ ticker }, isCompanyPage]) =>
        this.forecastApiService.getForecastEdgar(isCompanyPage, ticker).pipe(
          mergeMap(edgarHtml => [FORECAST_ACTIONS.getForecastEdgarSuccess({ edgarHtml })]),
          catchError(error => [FORECAST_ACTIONS.getForecastEdgarError({ ticker, error })])
        )
      )
    )
  )

  getComparableCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getComparableCompanies),
      withLatestFrom(
        this.companyFacade.comparableCompaniesCompanyId$,
        this.companyFacade.comparableCompanies$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId }, previousCompanyId, companyPeers, isCompanyPage]) => ({
        companyId,
        isCompanyPage,
        previousCompanyId,
        companyPeers: companyPeers || [],
      })),
      switchMap(({ companyId, previousCompanyId, companyPeers, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...companyPeers])
          : this.companyApiService.getPeers(companyId, isCompanyPage)
        ).pipe(
          mergeMap(companyPeers => [FORECAST_ACTIONS.getComparableCompaniesSuccess({ companyId, companyPeers })]),
          catchError(error => [FORECAST_ACTIONS.getComparableCompaniesError({ companyId, error })])
        )
      )
    )
  )

  setHistoricalPricesPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.setHistoricalPricesPeriod),
      withLatestFrom(this.companyFacade.historicalCompanyId$),
      map(([{ chartPeriod }, companyId]) => ({ chartPeriod, companyId })),
      filter(({ companyId }) => !!companyId),
      mergeMap(({ chartPeriod, companyId }) => [
        FORECAST_ACTIONS.getHistoricalPrices({ companyId: companyId!, chartPeriod }),
      ])
    )
  )

  getHistoricalPrices$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getHistoricalPrices),
      withLatestFrom(
        this.companyFacade.historicalCompanyId$,
        this.companyFacade.historicalCompanyPrices$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId, chartPeriod }, previousCompanyId, companyPrices, isCompanyPage]) => ({
        companyId,
        chartPeriod,
        isCompanyPage,
        previousCompanyId,
        companyPrices: companyPrices || [],
      })),
      switchMap(({ companyId, chartPeriod, previousCompanyId, companyPrices, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...companyPrices])
          : this.companyApiService.getPrices(companyId, chartPeriod, isCompanyPage)
        ).pipe(
          mergeMap(companyPrices => [
            FORECAST_ACTIONS.getHistoricalPricesSuccess({ companyId, companyPrices, chartPeriod }),
          ]),
          catchError(error => [FORECAST_ACTIONS.getHistoricalPricesError({ companyId, error })])
        )
      )
    )
  )

  getContourData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getContourData),
      withLatestFrom(
        this.companyFacade.contourCompanyId$,
        this.companyFacade.contourData$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId }, previousCompanyId, contourData, isCompanyPage]) => ({
        companyId,
        isCompanyPage,
        previousCompanyId,
        contourData: contourData || [],
      })),
      switchMap(({ companyId, previousCompanyId, contourData, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...contourData])
          : this.companyApiService.getContourEps(companyId, isCompanyPage)
        ).pipe(
          mergeMap(contourData => [FORECAST_ACTIONS.getContourDataSuccess({ companyId, contourData })]),
          catchError(error => [FORECAST_ACTIONS.getContourDataError({ companyId, error })])
        )
      )
    )
  )

  getIsolineData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getIsolineData),
      withLatestFrom(
        this.companyFacade.impactIsolinesCompanyId$,
        this.companyFacade.impactIsolinesData$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId }, previousCompanyId, isolineData, isCompanyPage]) => ({
        companyId,
        isCompanyPage,
        previousCompanyId,
        isolineData: isolineData || [],
      })),
      switchMap(({ companyId, previousCompanyId, isolineData, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...isolineData])
          : this.companyApiService.getImpactIsolines(companyId, isCompanyPage)
        ).pipe(
          mergeMap(isolineData => [FORECAST_ACTIONS.getIsolineDataSuccess({ companyId, isolineData })]),
          catchError(error => [FORECAST_ACTIONS.getIsolineDataError({ companyId, error })])
        )
      )
    )
  )

  getEpsPdfData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getEpsPdfData),
      withLatestFrom(
        this.companyFacade.epsPdfCompanyId$,
        this.companyFacade.epsPdfData$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId }, previousCompanyId, epsPdfData, isCompanyPage]) => ({
        companyId,
        isCompanyPage,
        previousCompanyId,
        epsPdfData: epsPdfData || [],
      })),
      switchMap(({ companyId, previousCompanyId, epsPdfData, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...epsPdfData])
          : this.companyApiService.getEpsPdf(companyId, isCompanyPage)
        ).pipe(
          mergeMap(data => [FORECAST_ACTIONS.getEpsPdfDataSuccess({ companyId, data })]),
          catchError(error => [FORECAST_ACTIONS.getEpsPdfDataError({ companyId, error })])
        )
      )
    )
  )

  getRevPdfData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getRevPdfData),
      withLatestFrom(
        this.companyFacade.revPdfCompanyId$,
        this.companyFacade.revPdfData$,
        this.routerFacade.isCompanyPage$
      ),
      map(([{ companyId }, previousCompanyId, revPdfData, isCompanyPage]) => ({
        companyId,
        isCompanyPage,
        previousCompanyId,
        revPdfData: revPdfData || [],
      })),
      switchMap(({ companyId, previousCompanyId, revPdfData, isCompanyPage }) =>
        (companyId === previousCompanyId
          ? of([...revPdfData])
          : this.companyApiService.getRevPdf(companyId, isCompanyPage)
        ).pipe(
          mergeMap(data => [FORECAST_ACTIONS.getRevPdfDataSuccess({ companyId, data })]),
          catchError(error => [FORECAST_ACTIONS.getRevPdfDataError({ companyId, error })])
        )
      )
    )
  )
}
