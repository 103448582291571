import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { ForecastListEffects } from './forecast-list.effects'
import { FORECAST_LIST_FACADE, ForecastListFacade } from './forecast-list.facade'
import { forecastListReducer } from './forecast-list.reducer'
import { forecastListFeatureKey } from './forecast-list.state'

const EFFECTS = [ForecastListEffects]

@NgModule({
  imports: [StoreModule.forFeature(forecastListFeatureKey, forecastListReducer), EffectsModule.forFeature(EFFECTS)],
  providers: [...EFFECTS, { provide: FORECAST_LIST_FACADE, useClass: ForecastListFacade }],
})
export class ForecastListModule {}
