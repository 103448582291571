import { makeStateKey } from '@angular/core'

import {
  ICompanyDto,
  ICompanyPriceDto,
  IContourEpsDto,
  IEpsPdfDto,
  IForecastDto,
  IIsolineEpsDto,
  IRevPdfDto,
} from '~/core/models'
import { EChartPeriod } from '~/core/models'

export const companyFeatureKey = 'Company prox'

export const companyStateKey = makeStateKey<ICompanyState>(companyFeatureKey)

export interface ICompanyState {
  inRequest: boolean
  inError: boolean
  data: {
    ticker?: string
    isExistForecastInfo: boolean
    isShowCancelOption?: boolean
    forecast?: IForecastDto | null
    edgarHtml?: string | null
    error?: Error | null
    comparableCompanies?: {
      inRequest?: boolean
      companyId?: number | null
      companyPeers?: ICompanyDto[]
      error?: Error | null
    }
    historical?: {
      inRequest?: boolean
      companyId?: number | null
      chartPeriod?: EChartPeriod
      companyPrices?: ICompanyPriceDto[]
      error?: Error | null
    }
    contour?: {
      inRequest?: boolean
      companyId?: number | null
      contourData?: IContourEpsDto[]
      error?: Error | null
    }
    impactIsolines?: {
      inRequest?: boolean
      companyId?: number | null
      isolineData?: IIsolineEpsDto[]
      error?: Error | null
    }
    epsPdf?: {
      inRequest?: boolean
      companyId?: number | null
      data?: IEpsPdfDto[]
      error?: Error | null
    }
    revPdf?: {
      inRequest?: boolean
      companyId?: number | null
      data?: IRevPdfDto[]
      error?: Error | null
    }
  }
}

export const createCompanyInitState = (): ICompanyState => ({
  inRequest: false,
  inError: false,
  data: {
    isExistForecastInfo: false,
  },
})

export interface IAppWithCompanyState {
  forecast: ICompanyState
}

export const createAppWithCompanyInitState = (): IAppWithCompanyState => ({
  forecast: createCompanyInitState(),
})
