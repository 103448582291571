import { Inject, Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { of } from 'rxjs'
import { catchError, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators'

import { QuarterlyDashboardApiService } from '~/core/api'

import * as QUARTERLY_DASHBOARD_ACTIONS from './quarterly-dashboard.actions'
import { QUARTERLY_DASHBOARD_MINIMUM_COMPANY_COUNT } from './quarterly-dashboard.constant'
import { IQuarterlyDashboardFacade, QUARTERLY_DASHBOARD_FACADE } from './quarterly-dashboard.facade'
import { IQuarterlyDashboardQuarter } from './quarterly-dashboard.model'

@Injectable()
export class QuarterlyDashboardEffects {
  constructor(
    private actions$: Actions,
    private quarterlyDashboardApiService: QuarterlyDashboardApiService,
    @Inject(QUARTERLY_DASHBOARD_FACADE) private quarterlyDashboardFacade: IQuarterlyDashboardFacade
  ) {}

  getQuarterlyDashboardQuarterList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterList),
      switchMap(() =>
        this.quarterlyDashboardApiService.getQuarterlyDashboardQuarterList().pipe(
          mergeMap(quarterList => {
            const quarter = this.getQuarter(quarterList)

            return [
              QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterListSuccess({ quarterList }),
              QUARTERLY_DASHBOARD_ACTIONS.setQuarterlyDashboardQuarter({ quarter }),
              QUARTERLY_DASHBOARD_ACTIONS.setDefaultQuarterlyDashboardQuarter({ quarter }),
            ]
          }),
          catchError(error => [QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterListError({ error })])
        )
      )
    )
  )

  // MktCap
  getQuarterlyDashboardMktCap$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCap),
      withLatestFrom(
        this.quarterlyDashboardFacade.quarter$,
        this.quarterlyDashboardFacade.mktCapQuarter$,
        this.quarterlyDashboardFacade.mktCapData$,
        this.quarterlyDashboardFacade.mktCapSortBy$,
        this.quarterlyDashboardFacade.mktCapOrder$
      ),
      switchMap(([{ sortBy, order }, quarter, mktCapQuarter, mktCapData, mktCapSortBy, mktCapOrder]) =>
        // Don't reload data if exist for the same quarter
        (quarter && (quarter !== mktCapQuarter || sortBy !== mktCapSortBy || order !== mktCapOrder)
          ? this.quarterlyDashboardApiService.getQuarterlyDashboardMktCap(quarter.year, quarter.quarter, sortBy, order)
          : of({
              order,
              sortBy,
              data: mktCapData || [],
            })
        ).pipe(
          mergeMap(({ data, sortBy, order }) => [
            QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCapSuccess({ data, quarter: quarter!, sortBy, order }),
          ]),
          catchError(error => [QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCapError({ error })])
        )
      )
    )
  )

  // Sector
  getQuarterlyDashboardSector$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSector),
      withLatestFrom(
        this.quarterlyDashboardFacade.quarter$,
        this.quarterlyDashboardFacade.sectorQuarter$,
        this.quarterlyDashboardFacade.sectorData$,
        this.quarterlyDashboardFacade.sectorSortBy$,
        this.quarterlyDashboardFacade.sectorOrder$
      ),
      switchMap(([{ sortBy, order }, quarter, sectorQuarter, sectorData, sectorSortBy, sectorOrder]) =>
        // Don't reload data if exist for the same quarter
        (quarter && (quarter !== sectorQuarter || sortBy !== sectorSortBy || order !== sectorOrder)
          ? this.quarterlyDashboardApiService.getQuarterlyDashboardSector(quarter.year, quarter.quarter, sortBy, order)
          : of({
              order,
              sortBy,
              data: sectorData || [],
            })
        ).pipe(
          mergeMap(({ data, sortBy, order }) => [
            QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorSuccess({ data, quarter: quarter!, sortBy, order }),
          ]),
          catchError(error => [QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorError({ error })])
        )
      )
    )
  )

  // Sector Top 500
  getQuarterlyDashboardSectorTop500$ = createEffect(() =>
    this.actions$.pipe(
      ofType(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500),
      withLatestFrom(
        this.quarterlyDashboardFacade.quarter$,
        this.quarterlyDashboardFacade.sectorTop500Quarter$,
        this.quarterlyDashboardFacade.sectorTop500Data$,
        this.quarterlyDashboardFacade.sectorTop500SortBy$,
        this.quarterlyDashboardFacade.sectorTop500Order$
      ),
      switchMap(
        ([{ sortBy, order }, quarter, sectorTop500Quarter, sectorTop500Data, sectorTop500SortBy, sectorTop500Order]) =>
          // Don't reload data if exist for the same quarter
          (quarter && (quarter !== sectorTop500Quarter || sortBy !== sectorTop500SortBy || order !== sectorTop500Order)
            ? this.quarterlyDashboardApiService.getQuarterlyDashboardSectorTop500(
                quarter.year,
                quarter.quarter,
                sortBy,
                order
              )
            : of({
                order,
                sortBy,
                data: sectorTop500Data || [],
              })
          ).pipe(
            mergeMap(({ data, sortBy, order }) => [
              QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500Success({
                data,
                quarter: quarter!,
                sortBy,
                order,
              }),
            ]),
            catchError(error => [QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500Error({ error })])
          )
      )
    )
  )

  private getQuarter(quarterList: IQuarterlyDashboardQuarter[]): IQuarterlyDashboardQuarter | null {
    if (!quarterList.length) {
      return null
    }

    for (let i = quarterList.length - 1; i >= 0; i--) {
      if (quarterList[i].reportedCount >= QUARTERLY_DASHBOARD_MINIMUM_COMPANY_COUNT) {
        return quarterList[i]
      }
    }
    return quarterList[0]
  }
}
