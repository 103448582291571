import { Inject, Injectable } from '@angular/core'
import { Router } from '@angular/router'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import _ from 'lodash-es'
import { combineLatest } from 'rxjs'
import { catchError, distinctUntilChanged, map, switchMap, tap, withLatestFrom } from 'rxjs/operators'

import { ForecastApiService, UserApiService } from '~/core/api'
import { ISearchForecastDataDto } from '~/core/models'
import { LocalStorageService } from '~/core/services'
// TODO: Remove link to pages
import { TAB_ALL_FORECASTS_LINK, TAB_FAVORITES_LINK, TAB_FOLDER_LINK } from '~/pages/dashboard/constants'
import { FORECAST_LIST_PATH, PAGINATION_DATA } from '~const/index'

import { COMPANY_FACADE, ICompanyFacade } from '../company'
import { ISearchForecastFacade, SEARCH_FORECAST_FACADE } from '../search-forecast'

import * as FORECAST_ACTIONS from './forecast-list.actions'
import { FORECAST_LIST_FACADE, IForecastListFacade } from './forecast-list.facade'
import { EDashRoute } from './forecast-list.model'

@Injectable()
export class ForecastListEffects {
  constructor(
    private actions$: Actions,
    private router: Router,
    private userApiService: UserApiService,
    private forecastApiService: ForecastApiService,
    private localStorageService: LocalStorageService,
    @Inject(COMPANY_FACADE) private companyFacade: ICompanyFacade,
    @Inject(FORECAST_LIST_FACADE) private forecastListFacade: IForecastListFacade,
    @Inject(SEARCH_FORECAST_FACADE) private searchForecastFacade: ISearchForecastFacade
  ) {}

  getForecastList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.getForecastList),
      withLatestFrom(this.forecastListFacade.searchData$, this.forecastListFacade.filterValues$),
      switchMap(([{ isFavorite, userFolderId, resetPagination }, searchData, filterValues]) =>
        combineLatest([
          this.searchForecastFacade.inCurrentFolder$,
          this.searchForecastFacade.forecastCompanyTicker$,
          this.searchForecastFacade.searchText$,
        ]).pipe(
          map(([inCurrentFolder, selectedTicker, search]) => ({
            isFavorite: inCurrentFolder || !search ? isFavorite : false,
            userFolderId: inCurrentFolder || !search ? userFolderId : null,
            searchData: {
              ...searchData,
              paginatorData: resetPagination
                ? { ...PAGINATION_DATA, perPage: this.localStorageService.getPaginatorPageSize() }
                : searchData.paginatorData,
              search,
              filterValues,
              selectedTicker,
            } as ISearchForecastDataDto,
          }))
        )
      ),
      distinctUntilChanged((prev, curr) => _.isEqual(prev, curr)), // If select forecast angular call onInit in an forecast list component
      tap(() => this.forecastListFacade.setInRequest()),
      switchMap(({ searchData, isFavorite, userFolderId }) =>
        this.forecastApiService.getForecastList(searchData, isFavorite, userFolderId).pipe(
          map(forecastsData => {
            return FORECAST_ACTIONS.getForecastListSuccess({ forecastsData })
          }),
          catchError(error => [FORECAST_ACTIONS.getForecastListError({ error })])
        )
      )
    )
  )

  changeSearchForecastData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.changeSearchForecastData),
      withLatestFrom(this.forecastListFacade.isFavorite$, this.forecastListFacade.userFolderId$),
      map(([, isFavorite, userFolderId]) => FORECAST_ACTIONS.getForecastList({ isFavorite, userFolderId }))
    )
  )

  toggleFavoriteForecast$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.toggleFavoriteForecasts),
      switchMap(({ companyIds }) =>
        this.userApiService.toggleFavoriteForecasts(companyIds).pipe(
          map(forecastRes => FORECAST_ACTIONS.toggleFavoriteForecastsSuccess({ forecastRes })),
          catchError(error => [FORECAST_ACTIONS.toggleFavoriteForecastsError({ error })])
        )
      )
    )
  )

  deleteForecastsFromFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FORECAST_ACTIONS.deleteForecastsFromFolder),
      withLatestFrom(this.forecastListFacade.userFolderId$),
      switchMap(([{ companyIds }, folderId]) =>
        this.userApiService.deleteForecastsFromFolder(companyIds, folderId).pipe(
          map(forecastRes => FORECAST_ACTIONS.deleteForecastsFromFolderSuccess({ forecastRes })),
          catchError(error => [FORECAST_ACTIONS.deleteForecastsFromFolderError({ error })])
        )
      )
    )
  )

  selectForecast$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(FORECAST_ACTIONS.selectForecast),
        withLatestFrom(
          this.forecastListFacade.selectedTicker2$,
          this.forecastListFacade.userFolderId$,
          this.forecastListFacade.selectedTab$,
          this.companyFacade.companyInfoTab$
        ),
        tap(([{ ticker }, selectedTicker, folderId, tab, companyInfoTab]) => {
          const url: (string | number | null | undefined)[] = ['/', FORECAST_LIST_PATH]

          switch (tab) {
            case EDashRoute.ALL_FORECASTS:
              url.push(TAB_ALL_FORECASTS_LINK)
              break
            case EDashRoute.FAVORITES:
              url.push(TAB_FAVORITES_LINK)
              break
            case EDashRoute.FOLDER:
              url.push(TAB_FOLDER_LINK)
              url.push(folderId)
              break
          }

          if (ticker && ticker !== selectedTicker) {
            url.push(ticker)

            if (companyInfoTab) {
              url.push(companyInfoTab)
            }
          }

          this.router.navigate(url, { queryParamsHandling: 'merge' })
        })
      ),
    { dispatch: false }
  )
}
