import { Action, createReducer, on } from '@ngrx/store'

import * as SEARCH_FORECAST_ACTIONS from './search-forecast.actions'
import { createSearchForecastInitState, ISearchForecastState } from './search-forecast.state'

export const searchForecastState = createReducer(
  createSearchForecastInitState(),

  on(SEARCH_FORECAST_ACTIONS.searchPreview, state => ({ ...state, inRequest: true })),
  on(SEARCH_FORECAST_ACTIONS.searchPreviewSuccess, (state, { searchOptions }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, searchCompanyPreview: searchOptions, error: null },
  })),
  on(SEARCH_FORECAST_ACTIONS.searchPreviewError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: { ...state.data, searchCompanyPreview: [], error },
  })),
  on(SEARCH_FORECAST_ACTIONS.clearSearchData, state => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      searchCompanyPreview: [],
    },
  })),
  on(SEARCH_FORECAST_ACTIONS.clearSearchCompanyPreview, state => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, searchCompanyPreview: [] },
  })),

  on(SEARCH_FORECAST_ACTIONS.changeCurrentFolderOption, (state, { inCurrentFolder }) => ({
    ...state,
    data: {
      ...state.data,
      inCurrentFolder,
    },
  }))
)

export const searchForecastReducer = (state: ISearchForecastState, action: Action): ISearchForecastState =>
  searchForecastState(state, action)
