import { createAction, props } from '@ngrx/store'

import { IForecastDto, IPaginationDto, IResponsePaginationApiDto, ISearchPaginationData } from '~/core/models'

import { forecastListFeatureKey } from './forecast-list.state'

export const setInRequest = createAction(`[${forecastListFeatureKey}] Set In Request`)

export const getForecastList = createAction(
  `[${forecastListFeatureKey}] Get Forecast List`,
  props<{
    isFavorite: boolean
    userFolderId: number
    resetPagination?: boolean
  }>()
)

export const getForecastListSuccess = createAction(
  `[${forecastListFeatureKey}] Get Forecast List Success`,
  props<{ forecastsData: IResponsePaginationApiDto<IForecastDto> }>()
)

export const getForecastListError = createAction(
  `[${forecastListFeatureKey}] Get Forecast List Error`,
  props<{ error: Error }>()
)

export const saveForecasts = createAction(
  `[${forecastListFeatureKey}] Save Forecasts`,
  props<{ forecasts: IForecastDto[] }>()
)

export const savePaginator = createAction(
  `[${forecastListFeatureKey}] Save Paginator`,
  props<{ paginator: IPaginationDto | null }>()
)

export const changeSearchForecastData = createAction(
  `[${forecastListFeatureKey}] Change Search Forecast Data`,
  props<{ searchForecastData: ISearchPaginationData }>()
)

export const changeFilterValuesForecastData = createAction(
  `[${forecastListFeatureKey}] Change Filter Values Forecast Data`,
  props<{ filterValuesForecastData: { [key: string]: any } }>()
)

export const resetForecasts = createAction(`[${forecastListFeatureKey}] Clear Forecast Data`)

export const toggleFavoriteForecasts = createAction(
  `[${forecastListFeatureKey}] Toggle Favorite Forecast`,
  props<{ companyIds: number[] }>()
)

export const toggleFavoriteForecastsSuccess = createAction(
  `[${forecastListFeatureKey}] Toggle Favorite Forecast Success`,
  props<{ forecastRes: { companyId: number; isFavorite: boolean }[] }>()
)

export const toggleFavoriteForecastsError = createAction(
  `[${forecastListFeatureKey}] Toggle Favorite Forecast Error`,
  props<{ error: Error }>()
)

export const deleteForecastsFromFolder = createAction(
  `[${forecastListFeatureKey}] Delete Forecasts From Folder`,
  props<{ companyIds: number[] }>()
)

export const deleteForecastsFromFolderSuccess = createAction(
  `[${forecastListFeatureKey}] Delete Forecasts From Folder Success`,
  props<{ forecastRes: number[] }>()
)

export const deleteForecastsFromFolderError = createAction(
  `[${forecastListFeatureKey}] Delete Forecasts From Folder Error`,
  props<{ error: Error }>()
)

export const moveForecasts = createAction(
  `[${forecastListFeatureKey}] Move Forecast`,
  props<{ companyIds: number[] }>()
)

export const moveForecastsSuccess = createAction(
  `[${forecastListFeatureKey}] Move Forecast Success`,
  props<{ forecastRes: { companyId: number }[] }>()
)

export const moveForecastsError = createAction(
  `[${forecastListFeatureKey}] Move Forecast Error`,
  props<{ error: Error }>()
)

export const selectForecast = createAction(`[${forecastListFeatureKey}] Select Forecast`, props<{ ticker: string }>())
