import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'
import { TranslateModule } from '@ngx-translate/core'

import { QuarterlyDashboardEffects } from './quarterly-dashboard.effects'
import { QUARTERLY_DASHBOARD_FACADE, QuarterlyDashboardFacade } from './quarterly-dashboard.facade'
import { quarterlyDashboardReducer } from './quarterly-dashboard.reducer'
import { quarterlyDashboardFeatureKey } from './quarterly-dashboard.state'

const EFFECTS = [QuarterlyDashboardEffects]

@NgModule({
  imports: [
    StoreModule.forFeature(quarterlyDashboardFeatureKey, quarterlyDashboardReducer),
    EffectsModule.forFeature(EFFECTS),
    TranslateModule,
  ],
  providers: [...EFFECTS, { provide: QUARTERLY_DASHBOARD_FACADE, useClass: QuarterlyDashboardFacade }],
})
export class QuarterlyDashboardModule {}
