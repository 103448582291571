import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { SearchForecastEffects } from './search-forecast.effects'
import { SEARCH_FORECAST_FACADE, SearchForecastFacade } from './search-forecast.facade'
import { searchForecastReducer } from './search-forecast.reducer'
import { searchForecastFeatureKey } from './search-forecast.state'

const EFFECTS = [SearchForecastEffects]

@NgModule({
  imports: [StoreModule.forFeature(searchForecastFeatureKey, searchForecastReducer), EffectsModule.forFeature(EFFECTS)],
  providers: [...EFFECTS, { provide: SEARCH_FORECAST_FACADE, useClass: SearchForecastFacade }],
})
export class SearchForecastModule {}
