import { IForecastDto, IPaginationDto, ISearchPaginationData } from '~/core/models'
import { PAGINATION_DATA } from '~const/index'

import { DEFAULT_ORDER, DEFAULT_ORDER_BY } from './forecast-list.constant'

export const forecastListFeatureKey = 'Forecast List prox'

export interface IForecastListState {
  inRequest: boolean
  inError: boolean
  data: {
    isFavorite: boolean // TODO: Remove
    userFolderId: number // TODO: Remove
    forecasts: IForecastDto[]
    selectedTicker: string // TODO: Remove
    paginator: IPaginationDto | null
    searchForecastData: ISearchPaginationData
    filterValuesForecastData: { [key: string]: any }
    error?: Error | null
  }
}

export const createForecastListInitState = (): IForecastListState => ({
  inRequest: false,
  inError: false,
  data: {
    isFavorite: false, // TODO: Remove
    userFolderId: -1, // TODO: Remove
    forecasts: [],
    selectedTicker: '', // TODO: Remove
    paginator: null,
    filterValuesForecastData: {},
    searchForecastData: {
      sortBy: DEFAULT_ORDER_BY,
      order: DEFAULT_ORDER,
      filters: {},
      paginatorData: { ...PAGINATION_DATA },
    },
  },
})

export interface IAppWithForecastListState {
  forecast: IForecastListState
}

export const createAppWithForecastListInitState = (): IAppWithForecastListState => ({
  forecast: createForecastListInitState(),
})
