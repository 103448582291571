import { createAction, props } from '@ngrx/store'

import { IUserDto, IUserFolderDto } from '~/core/models'

import { IUserState, userFeatureKey } from './user.state'

export const initState = createAction(`[${userFeatureKey}] Init state`, props<{ state: IUserState }>())

export const saveUser = createAction(`[${userFeatureKey}] Save user`, props<{ user: IUserDto | null | undefined }>())

export const initAuthUser = createAction(`[${userFeatureKey}] Init auth user`)

export const getAuthUser = createAction(`[${userFeatureKey}] Get auth user`)

export const getAuthUserSuccess = createAction(
  `[${userFeatureKey}] Get auth user success`,
  props<{ user: IUserDto | null | undefined }>()
)

export const getAuthUserError = createAction(`[${userFeatureKey}] Get auth user error`, props<{ error: Error }>())

export const getPermissionsSuccess = createAction(
  `[${userFeatureKey}] Get permissions success`,
  props<{
    entities?: { [key: string]: { [key: string]: { [key: string]: boolean } } }
    sections?: { [key: string]: boolean }
  }>()
)

export const getPermissionsError = createAction(`[${userFeatureKey}] Get permissions error`, props<{ error: Error }>())

export const getGuestPermissions = createAction(`[${userFeatureKey}] Get guest permissions`)

export const updateCurrentUser = createAction(
  `[${userFeatureKey}] Update current user`,
  props<{ firstName: string; lastName: string; companyName: string }>()
)

export const updateCurrentUserSuccess = createAction(
  `[${userFeatureKey}] Update current user success`,
  props<{ user: IUserDto }>()
)

export const updateCurrentUserError = createAction(
  `[${userFeatureKey}] Update current user error`,
  props<{ error: Error }>()
)

export const getUserFolders = createAction(`[${userFeatureKey}] Get user folders`)

export const getUserFoldersSuccess = createAction(
  `[${userFeatureKey}] Get user folders success`,
  props<{ userFolders: IUserFolderDto[] }>()
)

export const getUserFoldersError = createAction(`[${userFeatureKey}] Get user folders error`, props<{ error: Error }>())

export const addUserFolder = createAction(`[${userFeatureKey}] Add user folder`, props<{ folderName: string }>())

export const addUserFolderSuccess = createAction(
  `[${userFeatureKey}] Add user folder success`,
  props<{ userFolders: IUserFolderDto[] }>()
)

export const addUserFolderError = createAction(`[${userFeatureKey}] Add user folder error`, props<{ error: Error }>())

export const editUserFolder = createAction(
  `[${userFeatureKey}] Edit user folder`,
  props<{ folderId: number; folderName: string }>()
)

export const editUserFolderSuccess = createAction(
  `[${userFeatureKey}] Edit user folder success`,
  props<{ userFolder: IUserFolderDto }>()
)

export const editUserFolderError = createAction(`[${userFeatureKey}] Edit user folder error`, props<{ error: Error }>())

export const deleteUserFolder = createAction(`[${userFeatureKey}] Delete user folder`, props<{ folderId: number }>())

export const deleteUserFolderSuccess = createAction(
  `[${userFeatureKey}] Delete user folder success`,
  props<{ folderId: number }>()
)

export const deleteUserFolderError = createAction(
  `[${userFeatureKey}] Delete user folder error`,
  props<{ error: Error }>()
)

export const moveForecastsToUserFolder = createAction(
  `[${userFeatureKey}] Move forecasts to user folder`,
  props<{ folderId: number; companyIds: number[] }>()
)

export const moveForecastsToUserFolderSuccess = createAction(
  `[${userFeatureKey}] Move forecasts to user folder success`
)

export const moveForecastsToUserFolderError = createAction(
  `[${userFeatureKey}] Move forecasts to user folder error`,
  props<{ error: Error }>()
)

export const logout = createAction(`[${userFeatureKey}] Logout`)
