import { IForecastSearchPreviewDto } from '~/core/models'

export const searchForecastFeatureKey = 'Search Forecast Prox'

export interface ISearchForecastState {
  inRequest: boolean
  inError: boolean
  data: {
    searchCompanyPreview: IForecastSearchPreviewDto[]
    inCurrentFolder: boolean
    error?: Error | null
  }
}

export const createSearchForecastInitState = (): ISearchForecastState => ({
  inRequest: false,
  inError: false,
  data: {
    searchCompanyPreview: [],
    inCurrentFolder: true,
  },
})

export interface IAppWithSearchForecastState {
  forecast: ISearchForecastState
}

export const createAppWithSearchForecastInitState = (): IAppWithSearchForecastState => ({
  forecast: createSearchForecastInitState(),
})
