import { makeStateKey } from '@angular/core'

import { IUserDto, IUserFolderDto } from '~/core/models'

export const userFeatureKey = 'User prox'

export const userStateKey = makeStateKey<IUserState>(userFeatureKey)

export interface IUserState {
  inRequest: boolean
  inError: boolean
  data: {
    user?: IUserDto | null
    permissionSections?: { [key: string]: boolean | undefined } | null
    permissionSectionEntities?: { [key: string]: { [key: string]: { [key: string]: boolean } } } | null
    userFolders?: IUserFolderDto[]
    error?: Error | null
  }
}

export const createUserInitState = (): IUserState => ({
  inRequest: false,
  inError: false,
  data: {},
})

export interface IAppWithUserState {
  user: IUserState
}

export const createAppWithForecastInitState = (): IAppWithUserState => ({
  user: createUserInitState(),
})
