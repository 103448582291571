import { Injectable } from '@angular/core'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { TranslateService } from '@ngx-translate/core'
import { map, mergeMap } from 'rxjs/operators'

import { LocalStorageService } from '~/core/services'
import { ESnackBarType, SnackBarService } from '~/ui/snack-bar'

import * as UI_ACTIONS from './ui.actions'

@Injectable()
export class UiEffects {
  constructor(
    private actions$: Actions,
    private translate: TranslateService,
    private snackBarService: SnackBarService,
    private localStorageService: LocalStorageService
  ) {}

  initUi$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UI_ACTIONS.initUi),
      mergeMap(() => {
        let isDarkTheme = this.localStorageService.isDarkTheme()
        isDarkTheme = isDarkTheme || isDarkTheme === null
        const language = this.localStorageService.getLanguage()
        return [UI_ACTIONS.saveThemeMode({ isDarkTheme }), UI_ACTIONS.saveLanguage({ language })]
      })
    )
  )

  changeThemeMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UI_ACTIONS.changeThemeMode),
      map(({ isDarkTheme }) => {
        this.localStorageService.changeThemeMode(isDarkTheme)
        return UI_ACTIONS.saveThemeMode({ isDarkTheme })
      })
    )
  )

  changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UI_ACTIONS.changeLanguage),
      map(() => {
        let language = this.localStorageService.getLanguage()
        this.localStorageService.changeLanguage(language)

        return UI_ACTIONS.saveLanguage({ language })
      })
    )
  )

  saveLanguage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UI_ACTIONS.saveLanguage),
        map(({ language }) => this.translate.use(language))
      ),
    { dispatch: false }
  )

  addErrorNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UI_ACTIONS.addErrorNotification),
      map(({ messageI18n, messageKeyI18n }) =>
        UI_ACTIONS.addNotification({
          snackBarData: {
            messageI18n,
            messageKeyI18n,
            type: ESnackBarType.ERROR,
          },
        })
      )
    )
  )

  addInfoNotification$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UI_ACTIONS.addInfoNotification),
      map(({ messageI18n, messageKeyI18n }) =>
        UI_ACTIONS.addNotification({
          snackBarData: {
            messageI18n,
            messageKeyI18n,
            type: ESnackBarType.INFO,
          },
        })
      )
    )
  )

  addNotification$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UI_ACTIONS.addNotification),
        map(({ snackBarData }) => this.snackBarService.openSnackBar(snackBarData))
      ),
    { dispatch: false }
  )
}
