import { Action, createReducer, on } from '@ngrx/store'

import { IUserFolderDto } from '~/core/models'

import * as USER_ACTIONS from './user.actions'
import { createUserInitState, IUserState } from './user.state'

export const userState = createReducer(
  createUserInitState(),

  on(USER_ACTIONS.initState, (state, { state: initState }) => ({ ...state, ...initState })),

  on(USER_ACTIONS.getAuthUser, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.getAuthUserSuccess, (state, { user }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, user, error: null },
  })),
  on(USER_ACTIONS.getAuthUserError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: { user: null, permissionSections: null, permissionSectionEntities: null, userFolders: [], error },
  })),

  on(
    USER_ACTIONS.getPermissionsSuccess,
    (state, { sections: permissionSections, entities: permissionSectionEntities }) => ({
      ...state,
      inRequest: false,
      inError: false,
      data: { ...state.data, permissionSections, permissionSectionEntities, error: null },
    })
  ),
  on(USER_ACTIONS.getPermissionsError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: { user: null, permissionSections: null, permissionSectionEntities: null, userFolders: [], error },
  })),

  on(USER_ACTIONS.updateCurrentUser, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.updateCurrentUserSuccess, (state, { user }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, user, error: null },
  })),
  on(USER_ACTIONS.updateCurrentUserError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: { ...state.data, error },
  })),

  on(USER_ACTIONS.getUserFolders, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.getUserFoldersSuccess, (state, { userFolders }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: { ...state.data, userFolders, error: null },
  })),
  on(USER_ACTIONS.getUserFoldersError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: { user: undefined, userFolders: [], error },
  })),

  on(USER_ACTIONS.addUserFolder, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.addUserFolderSuccess, (state, { userFolders }) => {
    const data = { ...state.data, userFolders, error: null }
    return {
      ...state,
      inRequest: false,
      inError: false,
      data,
    }
  }),
  on(USER_ACTIONS.addUserFolderError, (state, { error }) => {
    const data = { ...state.data, error }
    return {
      ...state,
      inRequest: false,
      inError: true,
      data,
    }
  }),

  on(USER_ACTIONS.editUserFolder, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.editUserFolderSuccess, (state, { userFolder }) => {
    const userFolders = (state.data.userFolders || []).reduce((folders: IUserFolderDto[], folder: IUserFolderDto) => {
      folders.push(folder.id === userFolder.id ? userFolder : folder)
      return folders
    }, [])

    const data = { ...state.data, userFolders, error: null }
    return {
      ...state,
      inRequest: false,
      inError: false,
      data,
    }
  }),
  on(USER_ACTIONS.editUserFolderError, (state, { error }) => {
    const data = { ...state.data, error }
    return {
      ...state,
      inRequest: false,
      inError: true,
      data,
    }
  }),

  on(USER_ACTIONS.deleteUserFolder, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.deleteUserFolderSuccess, (state, { folderId }) => {
    const userFolders = (state.data.userFolders || []).filter(f => f.id !== folderId)
    const data = { ...state.data, userFolders, error: null }
    return {
      ...state,
      inRequest: false,
      inError: false,
      data,
    }
  }),
  on(USER_ACTIONS.deleteUserFolderError, (state, { error }) => {
    const data = { ...state.data, error }
    return {
      ...state,
      inRequest: false,
      inError: true,
      data,
    }
  }),

  on(USER_ACTIONS.moveForecastsToUserFolder, state => ({ ...state, inRequest: true })),
  on(USER_ACTIONS.moveForecastsToUserFolderSuccess, state => {
    const data = { ...state.data, error: null }
    return {
      ...state,
      inRequest: false,
      inError: false,
      data,
    }
  }),
  on(USER_ACTIONS.moveForecastsToUserFolderError, (state, { error }) => {
    const data = { ...state.data, error }
    return {
      ...state,
      inRequest: false,
      inError: true,
      data,
    }
  })
)

export const userReducer = (state: IUserState, action: Action): IUserState => userState(state, action)
