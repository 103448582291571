import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import * as QUARTERLY_DASHBOARD_ACTIONS from './quarterly-dashboard.actions'
import {
  EQuarterlyDashboardViewMode,
  IQuarterlyDashboardMktCap,
  IQuarterlyDashboardQuarter,
  IQuarterlyDashboardSector,
  IQuarterlyDashboardSectorTop500,
} from './quarterly-dashboard.model'
import {
  selectQuarterlyDashboardDefaultQuarter,
  selectQuarterlyDashboardError,
  selectQuarterlyDashboardInError,
  selectQuarterlyDashboardInRequest,
  selectQuarterlyDashboardMktCapData,
  selectQuarterlyDashboardMktCapError,
  selectQuarterlyDashboardMktCapInRequest,
  selectQuarterlyDashboardMktCapOrder,
  selectQuarterlyDashboardMktCapQuarter,
  selectQuarterlyDashboardMktCapSortBy,
  selectQuarterlyDashboardQuarter,
  selectQuarterlyDashboardQuarterList,
  selectQuarterlyDashboardSectorData,
  selectQuarterlyDashboardSectorError,
  selectQuarterlyDashboardSectorInRequest,
  selectQuarterlyDashboardSectorOrder,
  selectQuarterlyDashboardSectorQuarter,
  selectQuarterlyDashboardSectorSortBy,
  selectQuarterlyDashboardSectorTop500Data,
  selectQuarterlyDashboardSectorTop500Error,
  selectQuarterlyDashboardSectorTop500InRequest,
  selectQuarterlyDashboardSectorTop500Order,
  selectQuarterlyDashboardSectorTop500Quarter,
  selectQuarterlyDashboardSectorTop500SortBy,
  selectQuarterlyDashboardViewMode,
} from './quarterly-dashboard.selectors'
import { IAppWithQuarterlyDashboardState } from './quarterly-dashboard.state'

export interface IQuarterlyDashboardFacade {
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  error$: Observable<Error | null | undefined>

  quarterList$: Observable<IQuarterlyDashboardQuarter[]>
  quarter$: Observable<IQuarterlyDashboardQuarter | null | undefined>
  defaultQuarter$: Observable<IQuarterlyDashboardQuarter | null | undefined>

  mktCapInRequest$: Observable<boolean | undefined>
  mktCapError$: Observable<Error | null | undefined>
  mktCapQuarter$: Observable<IQuarterlyDashboardQuarter | undefined>
  mktCapData$: Observable<IQuarterlyDashboardMktCap[] | undefined>
  mktCapSortBy$: Observable<string | undefined>
  mktCapOrder$: Observable<'asc' | 'desc' | '' | undefined>

  sectorInRequest$: Observable<boolean | undefined>
  sectorError$: Observable<Error | null | undefined>
  sectorQuarter$: Observable<IQuarterlyDashboardQuarter | undefined>
  sectorData$: Observable<IQuarterlyDashboardSector[] | undefined>
  sectorSortBy$: Observable<string | undefined>
  sectorOrder$: Observable<'asc' | 'desc' | '' | undefined>

  sectorTop500InRequest$: Observable<boolean | undefined>
  sectorTop500Error$: Observable<Error | null | undefined>
  sectorTop500Quarter$: Observable<IQuarterlyDashboardQuarter | undefined>
  sectorTop500Data$: Observable<IQuarterlyDashboardSectorTop500[] | undefined>
  sectorTop500SortBy$: Observable<string | undefined>
  sectorTop500Order$: Observable<'asc' | 'desc' | '' | undefined>

  viewMode$: Observable<EQuarterlyDashboardViewMode>

  getQuarterlyDashboardQuarterList(): void
  changeQuarterlyDashboardQuarter(quarter: IQuarterlyDashboardQuarter): void
  getQuarterlyDashboardMktCap(sortBy: string, order: 'asc' | 'desc' | ''): void
  getQuarterlyDashboardSector(sortBy: string, order: 'asc' | 'desc' | ''): void
  getQuarterlyDashboardSectorTop500(sortBy: string, order: 'asc' | 'desc' | ''): void
  changeQuarterlyDashboardViewMode(viewMode: EQuarterlyDashboardViewMode): void
}

export const QUARTERLY_DASHBOARD_FACADE = new InjectionToken<IQuarterlyDashboardFacade>('QUARTERLY_DASHBOARD_FACADE')

@Injectable()
export class QuarterlyDashboardFacade implements IQuarterlyDashboardFacade {
  inRequest$ = this.store$.select(selectQuarterlyDashboardInRequest)
  inError$ = this.store$.select(selectQuarterlyDashboardInError)
  error$ = this.store$.select(selectQuarterlyDashboardError)

  quarterList$ = this.store$.select(selectQuarterlyDashboardQuarterList)
  quarter$ = this.store$.select(selectQuarterlyDashboardQuarter)
  defaultQuarter$ = this.store$.select(selectQuarterlyDashboardDefaultQuarter)

  mktCapInRequest$ = this.store$.select(selectQuarterlyDashboardMktCapInRequest)
  mktCapError$ = this.store$.select(selectQuarterlyDashboardMktCapError)
  mktCapQuarter$ = this.store$.select(selectQuarterlyDashboardMktCapQuarter)
  mktCapData$ = this.store$.select(selectQuarterlyDashboardMktCapData)
  mktCapSortBy$ = this.store$.select(selectQuarterlyDashboardMktCapSortBy)
  mktCapOrder$ = this.store$.select(selectQuarterlyDashboardMktCapOrder)

  sectorInRequest$ = this.store$.select(selectQuarterlyDashboardSectorInRequest)
  sectorError$ = this.store$.select(selectQuarterlyDashboardSectorError)
  sectorQuarter$ = this.store$.select(selectQuarterlyDashboardSectorQuarter)
  sectorData$ = this.store$.select(selectQuarterlyDashboardSectorData)
  sectorSortBy$ = this.store$.select(selectQuarterlyDashboardSectorSortBy)
  sectorOrder$ = this.store$.select(selectQuarterlyDashboardSectorOrder)

  sectorTop500InRequest$ = this.store$.select(selectQuarterlyDashboardSectorTop500InRequest)
  sectorTop500Error$ = this.store$.select(selectQuarterlyDashboardSectorTop500Error)
  sectorTop500Quarter$ = this.store$.select(selectQuarterlyDashboardSectorTop500Quarter)
  sectorTop500Data$ = this.store$.select(selectQuarterlyDashboardSectorTop500Data)
  sectorTop500SortBy$ = this.store$.select(selectQuarterlyDashboardSectorTop500SortBy)
  sectorTop500Order$ = this.store$.select(selectQuarterlyDashboardSectorTop500Order)

  viewMode$ = this.store$.select(selectQuarterlyDashboardViewMode)

  constructor(private store$: Store<IAppWithQuarterlyDashboardState>) {}

  getQuarterlyDashboardQuarterList(): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardQuarterList())
  }

  changeQuarterlyDashboardQuarter(quarter: IQuarterlyDashboardQuarter): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.setQuarterlyDashboardQuarter({ quarter }))
  }

  getQuarterlyDashboardMktCap(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardMktCap({ sortBy, order }))
  }

  getQuarterlyDashboardSector(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSector({ sortBy, order }))
  }

  getQuarterlyDashboardSectorTop500(sortBy: string, order: 'asc' | 'desc' | ''): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.getQuarterlyDashboardSectorTop500({ sortBy, order }))
  }

  changeQuarterlyDashboardViewMode(viewMode: EQuarterlyDashboardViewMode): void {
    this.store$.dispatch(QUARTERLY_DASHBOARD_ACTIONS.setQuarterlyDashboardViewMode({ viewMode }))
  }
}
