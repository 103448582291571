import { Action, createReducer, on } from '@ngrx/store'

import * as FORECAST_LIST_ACTIONS from './forecast-list.actions'
import { createForecastListInitState, IForecastListState } from './forecast-list.state'

export const forecastListState = createReducer(
  createForecastListInitState(),

  on(FORECAST_LIST_ACTIONS.setInRequest, state => ({ ...state, inRequest: true, inError: false })),

  on(FORECAST_LIST_ACTIONS.getForecastList, (state, { isFavorite, userFolderId }) => ({
    ...state,
    data: {
      ...state.data,
      isFavorite,
      userFolderId,
    },
  })),
  on(FORECAST_LIST_ACTIONS.getForecastListSuccess, (state, { forecastsData }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      forecasts: forecastsData.data,
      paginator: forecastsData.pagination,
      error: null,
    },
  })),
  on(FORECAST_LIST_ACTIONS.getForecastListError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: {
      ...state.data,
      forecasts: [],
      error,
    },
  })),

  on(FORECAST_LIST_ACTIONS.toggleFavoriteForecasts, state => ({ ...state, inRequest: true })),
  on(FORECAST_LIST_ACTIONS.toggleFavoriteForecastsSuccess, (state, { forecastRes }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      forecasts: state.data.forecasts.map(forecast => {
        const changedForecastRes = forecastRes.find(fr => fr.companyId === forecast.company.companyId)
        if (changedForecastRes) {
          return {
            ...forecast,
            isFavorite: changedForecastRes.isFavorite,
          }
        }
        return { ...forecast }
      }),
      error: null,
    },
  })),

  on(FORECAST_LIST_ACTIONS.deleteForecastsFromFolder, state => ({ ...state, inRequest: true })),
  on(FORECAST_LIST_ACTIONS.deleteForecastsFromFolderSuccess, (state, { forecastRes }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      forecasts: state.data.forecasts.filter(forecast => !forecastRes.includes(forecast.company.companyId)),
      error: null,
    },
  })),
  on(FORECAST_LIST_ACTIONS.deleteForecastsFromFolderError, (state, { error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: {
      ...state.data,
      error,
    },
  })),

  on(FORECAST_LIST_ACTIONS.saveForecasts, (state, { forecasts }) => ({
    ...state,
    data: { ...state.data, forecasts },
  })),

  on(FORECAST_LIST_ACTIONS.savePaginator, (state, { paginator }) => ({
    ...state,
    data: { ...state.data, paginator },
  })),

  on(FORECAST_LIST_ACTIONS.changeSearchForecastData, (state, { searchForecastData }) => ({
    ...state,
    data: { ...state.data, searchForecastData },
  })),

  on(FORECAST_LIST_ACTIONS.changeFilterValuesForecastData, (state, { filterValuesForecastData }) => ({
    ...state,
    data: { ...state.data, filterValuesForecastData },
  })),

  on(FORECAST_LIST_ACTIONS.resetForecasts, () => createForecastListInitState()),

  on(FORECAST_LIST_ACTIONS.selectForecast, (state, { ticker }) => ({
    ...state,
    data: {
      ...state.data,
      selectedTicker: ticker,
    },
  }))
)

export const forecastListReducer = (state: IForecastListState, action: Action): IForecastListState =>
  forecastListState(state, action)
