import { APP_DEFAULT_LANGUAGE } from '~/core/features'
import { EGuardEntityName, ITableGroupedColumn } from '~/core/models'
import { compactNumber, forecastPercentNumber } from '~/utils/index'

import {
  EQuarterlyDashboardPath,
  EQuarterlyDashboardSummaryFlag,
  EQuarterlyDashboardViewMode,
} from './quarterly-dashboard.model'

const createColumn = function (
  name: string,
  isPercent: boolean = false,
  isGrouped: boolean = false,
  classes?: { [key: string]: boolean }
): ITableGroupedColumn {
  const column: ITableGroupedColumn = {
    name: name,
    key: name,
    guardEntityField: 'reportedCount', // TODO: Permission
    guardEntityName: EGuardEntityName.ADDL_PRED, // TODO: Permission
    i18nName: `quarterlyDashboard.ratesTable.${name}`,
    classes: { 'text-center': true, 'no-wrap': true, ...classes },
  }
  if (isPercent) {
    column.title = (v: number): string => forecastPercentNumber(v, 1)
  }
  if (isGrouped) {
    column.isGrouped = true
  }
  return column
}

const WALL_STREET_CONSENSUS_COLUMNS: ITableGroupedColumn[] = [
  createColumn('epsEstimateBeatPercent', true),
  createColumn('revEstimateBeatPercent', true),
  createColumn('hedgedTotretPostimpact1', true, true),
  createColumn('epsEstimateBeatHtp1', true, true),
  createColumn('epsEstimateMissHtp1', true, true),
  createColumn('revEstimateBeatHtp1', true, true),
  createColumn('revEstimateMissHtp1', true, true),
  createColumn('reportedCount', false, false, { 'sm-column': true }),
]

const PROXIMILAR_AI_FORECAST_COLUMNS: ITableGroupedColumn[] = [
  createColumn('epsForecastBeatPercent', true),
  createColumn('revForecastBeatPercent', true),
  createColumn('hedgedTotretPostimpact1', true, true),
  createColumn('epsForecastBeatHtp1', true, true),
  createColumn('epsForecastMissHtp1', true, true),
  createColumn('revForecastBeatHtp1', true, true),
  createColumn('revForecastMissHtp1', true, true),
  createColumn('reportedCount', false, false, { 'sm-column': true }),
]

const MARKET_CAP_COLUMNS: ITableGroupedColumn[] = [
  {
    name: 'mktCapFrom',
    key: 'mktCapFrom',
    isTotalColumn: true,
    classes: { 'sm-column': true },
    guardEntityField: 'reportedCount', // TODO: Permission
    guardEntityName: EGuardEntityName.ADDL_PRED, // TODO: Permission
    i18nName: 'quarterlyDashboard.ratesByMarketCap.mktCapFrom',
    title: (v: number) => compactNumber(v, APP_DEFAULT_LANGUAGE),
  },
  {
    name: 'mktCapTo',
    key: 'mktCapTo',
    classes: { 'md-column': true },
    guardEntityField: 'reportedCount', // TODO: Permission
    guardEntityName: EGuardEntityName.ADDL_PRED, // TODO: Permission
    i18nName: 'quarterlyDashboard.ratesByMarketCap.mktCapTo',
    title: (v: number, summaryFlag: EQuarterlyDashboardSummaryFlag) =>
      compactNumber(
        v,
        APP_DEFAULT_LANGUAGE,
        summaryFlag === EQuarterlyDashboardSummaryFlag.SUMMARY_COLUMN ? '' : 'quarterlyDashboard.numFromTo.greater'
      ),
  },
]

const SECTOR_COLUMNS: ITableGroupedColumn[] = [
  {
    name: 'sector',
    key: 'sector',
    isTotalColumn: true,
    guardEntityField: 'expected_impact_at_act', // TODO: Permission
    guardEntityName: EGuardEntityName.ADDL_PRED, // TODO: Permission
    i18nName: 'quarterlyDashboard.ratesBySector.sector',
  },
]

const SECTOR_TOP500_COLUMNS: ITableGroupedColumn[] = [
  {
    name: 'sector',
    key: 'sector',
    isTotalColumn: true,
    guardEntityField: 'expected_impact_at_act', // TODO: Permission
    guardEntityName: EGuardEntityName.ADDL_PRED, // TODO: Permission
    i18nName: 'quarterlyDashboard.ratesBySectorTop500.sector',
  },
]

export const getQuarterlyDashboardColumns = function (
  path: EQuarterlyDashboardPath,
  viewMode: EQuarterlyDashboardViewMode
): ITableGroupedColumn[] {
  let prefixColumns = []
  switch (path) {
    case EQuarterlyDashboardPath.MARKET_CAP:
      prefixColumns = MARKET_CAP_COLUMNS
      break

    case EQuarterlyDashboardPath.SECTOR:
      prefixColumns = SECTOR_COLUMNS
      break

    case EQuarterlyDashboardPath.SECTOR_TOP500:
      prefixColumns = SECTOR_TOP500_COLUMNS
      break
  }

  let suffixColumns = []
  switch (viewMode) {
    case EQuarterlyDashboardViewMode.WALL_STREET_CONSENSUS:
      suffixColumns = WALL_STREET_CONSENSUS_COLUMNS
      break

    case EQuarterlyDashboardViewMode.PROXIMILAR_AI_FORECAST:
      suffixColumns = PROXIMILAR_AI_FORECAST_COLUMNS
      break
  }

  return [...prefixColumns, ...suffixColumns]
}
