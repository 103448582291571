import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { IForecastDto, IPaginationDto, ISearchPaginationData } from '~/core/models'

import * as FORECAST_ACTIONS from './forecast-list.actions'
import { EDashRoute } from './forecast-list.model'
import {
  selectFilterValuesForecastListData,
  selectForecastByTicker,
  selectForecastListError,
  selectForecastListInError,
  selectForecastListInRequest,
  selectForecastListPaginator,
  selectForecastListSearchData,
  selectForecasts,
  selectIsFavorite,
  selectSelectedTab,
  selectSelectedTicker,
  selectSelectedTicker2,
  selectUserFolderId,
  selectUserFolderId2,
} from './forecast-list.selectors'
import { IAppWithForecastListState } from './forecast-list.state'

export interface IForecastListFacade {
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  error$: Observable<Error | null | undefined>

  isFavorite$: Observable<boolean>
  userFolderId$: Observable<number>
  selectedTicker$: Observable<string>
  forecasts$: Observable<IForecastDto[]>
  paginator$: Observable<IPaginationDto | null>

  searchData$: Observable<ISearchPaginationData>
  filterValues$: Observable<{ [key: string]: any }>
  selectedTab$: Observable<EDashRoute | undefined>
  selectedTicker2$: Observable<string | undefined>
  selectedUserFolderId2$: Observable<number | null>

  setInRequest(): void
  getForecastList(isFavorite?: boolean, userFolderId?: number, resetPagination?: boolean): void
  getForecastByTicker(ticker: string): Observable<IForecastDto | undefined>
  selectForecast(ticker: string): void
  toggleFavoriteForecast(companyIds: number[]): void
  deleteForecastsFromFolder(companyIds: number[]): void
  changeSearchForecastData(searchForecastData: ISearchPaginationData): void
  changeFilterValuesForecastData(filterValuesForecastData: { [key: string]: any }): void
  resetForecast(): void
}

export const FORECAST_LIST_FACADE = new InjectionToken<IForecastListFacade>('FORECAST_LIST_FACADE')

@Injectable()
export class ForecastListFacade implements IForecastListFacade {
  inRequest$ = this.store$.select(selectForecastListInRequest)
  inError$ = this.store$.select(selectForecastListInError)
  error$ = this.store$.select(selectForecastListError)

  isFavorite$ = this.store$.select(selectIsFavorite)
  userFolderId$ = this.store$.select(selectUserFolderId)
  selectedTicker$ = this.store$.select(selectSelectedTicker)
  forecasts$ = this.store$.select(selectForecasts)
  paginator$ = this.store$.select(selectForecastListPaginator)

  searchData$ = this.store$.select(selectForecastListSearchData)
  filterValues$ = this.store$.select(selectFilterValuesForecastListData)
  selectedTab$ = this.store$.select(selectSelectedTab)
  selectedTicker2$ = this.store$.select(selectSelectedTicker2)
  selectedUserFolderId2$ = this.store$.select(selectUserFolderId2)

  constructor(private store$: Store<IAppWithForecastListState>) {}

  setInRequest(): void {
    this.store$.dispatch(FORECAST_ACTIONS.setInRequest())
  }

  getForecastList(isFavorite = false, userFolderId = -1, resetPagination = false): void {
    this.store$.dispatch(FORECAST_ACTIONS.getForecastList({ isFavorite, userFolderId, resetPagination }))
  }

  getForecastByTicker(ticker: string): Observable<IForecastDto | undefined> {
    return this.store$.select(selectForecastByTicker(ticker))
  }

  selectForecast(ticker: string): void {
    this.store$.dispatch(FORECAST_ACTIONS.selectForecast({ ticker }))
  }

  toggleFavoriteForecast(companyIds: number[]): void {
    this.store$.dispatch(FORECAST_ACTIONS.toggleFavoriteForecasts({ companyIds }))
  }

  deleteForecastsFromFolder(companyIds: number[]): void {
    this.store$.dispatch(FORECAST_ACTIONS.deleteForecastsFromFolder({ companyIds }))
  }

  changeSearchForecastData(searchForecastData: ISearchPaginationData): void {
    this.store$.dispatch(FORECAST_ACTIONS.changeSearchForecastData({ searchForecastData }))
  }

  changeFilterValuesForecastData(filterValuesForecastData: { [key: string]: any }): void {
    this.store$.dispatch(FORECAST_ACTIONS.changeFilterValuesForecastData({ filterValuesForecastData }))
  }

  resetForecast(): void {
    this.store$.dispatch(FORECAST_ACTIONS.resetForecasts())
  }
}
