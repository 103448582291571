import { EQuarterlyDashboardViewMode } from './quarterly-dashboard.model'

export const QUARTERLY_DASHBOARD_MINIMUM_COMPANY_COUNT = 350
export const QUARTERLY_DASHBOARD_TOTAL_LIST_COUNT = 6

export const QUARTERLY_DASHBOARD_DEFAULT_MKT_CAP_SORT_BY = 'mktCapFrom'
export const QUARTERLY_DASHBOARD_DEFAULT_MKT_CAP_ORDER: 'asc' | 'desc' = 'desc'

export const QUARTERLY_DASHBOARD_DEFAULT_SECTOR_SORT_BY = 'sector'
export const QUARTERLY_DASHBOARD_DEFAULT_SECTOR_ORDER: 'asc' | 'desc' = 'asc'

export const QUARTERLY_DASHBOARD_DEFAULT_SECTOR_TOP500_SORT_BY = 'sector'
export const QUARTERLY_DASHBOARD_DEFAULT_SECTOR_TOP500_ORDER: 'asc' | 'desc' = 'asc'

export const QUARTERLY_DASHBOARD_DEFAULT_VIEW_MODE: EQuarterlyDashboardViewMode =
  EQuarterlyDashboardViewMode.PROXIMILAR_AI_FORECAST
