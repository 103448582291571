import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import {
  EChartPeriod,
  ICompanyDto,
  ICompanyPriceDto,
  IContourEpsDto,
  IEpsPdfDto,
  IForecastDto,
  IIsolineEpsDto,
  IRevPdfDto,
} from '~/core/models'

import * as COMPANY_ACTIONS from './company.actions'
import { ECompanyInfoRoute } from './company.model'
import {
  selectCompanyInError,
  selectCompanyInfoTab,
  selectCompanyInRequest,
  selectCompanyState,
  selectComparableCompaniesCompanyId,
  selectComparableCompaniesCompanyPeers,
  selectComparableCompaniesError,
  selectComparableCompaniesInRequest,
  selectContourCompanyId,
  selectContourData,
  selectContourError,
  selectContourInRequest,
  selectEdgarHtml,
  selectEpsPdfCompanyId,
  selectEpsPdfData,
  selectEpsPdfError,
  selectEpsPdfInRequest,
  selectForecast,
  selectForecastListError,
  selectHistoricalChartPeriod,
  selectHistoricalCompanyId,
  selectHistoricalCompanyPrices,
  selectHistoricalError,
  selectHistoricalInRequest,
  selectImpactIsolinesCompanyId,
  selectImpactIsolinesData,
  selectImpactIsolinesError,
  selectImpactIsolinesInRequest,
  selectIsExistForecastInfo,
  selectIsShowCancelOption,
  selectRevPdfCompanyId,
  selectRevPdfData,
  selectRevPdfError,
  selectRevPdfInRequest,
  selectTicker,
} from './company.selectors'
import { IAppWithCompanyState, ICompanyState } from './company.state'

export interface ICompanyFacade {
  state$: Observable<ICompanyState>
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  error$: Observable<Error | null | undefined>

  ticker$: Observable<string | undefined>
  isExistForecastInfo$: Observable<boolean>
  isShowCancelOption$: Observable<boolean | undefined>
  forecast$: Observable<IForecastDto | null | undefined>
  edgarHtml$: Observable<string>

  comparableCompanies$: Observable<ICompanyDto[] | undefined>
  comparableCompaniesError$: Observable<Error | null | undefined>
  comparableCompaniesCompanyId$: Observable<number | null | undefined>
  comparableCompaniesInRequest$: Observable<boolean | undefined>

  historicalCompanyPrices$: Observable<ICompanyPriceDto[] | undefined>
  historicalError$: Observable<Error | null | undefined>
  historicalChartPeriod$: Observable<EChartPeriod | undefined>
  historicalCompanyId$: Observable<number | null | undefined>
  historicalInRequest$: Observable<boolean | undefined>

  contourData$: Observable<IContourEpsDto[] | undefined>
  contourError$: Observable<Error | null | undefined>
  contourCompanyId$: Observable<number | null | undefined>
  contourInRequest$: Observable<boolean | undefined>

  impactIsolinesData$: Observable<IIsolineEpsDto[] | undefined>
  impactIsolinesError$: Observable<Error | null | undefined>
  impactIsolinesCompanyId$: Observable<number | null | undefined>
  impactIsolinesInRequest$: Observable<boolean | undefined>

  epsPdfData$: Observable<IEpsPdfDto[] | undefined>
  epsPdfError$: Observable<Error | null | undefined>
  epsPdfCompanyId$: Observable<number | null | undefined>
  epsPdfInRequest$: Observable<boolean | undefined>

  revPdfData$: Observable<IRevPdfDto[] | undefined>
  revPdfError$: Observable<Error | null | undefined>
  revPdfCompanyId$: Observable<number | null | undefined>
  revPdfInRequest$: Observable<boolean | undefined>

  companyInfoTab$: Observable<ECompanyInfoRoute | undefined>

  initState(state: ICompanyState): void

  showCancelOption(showCancelOption: boolean): void
  getForecast(ticker: string): void
  getForecastEdgar(ticker: string): void
  saveForecast(forecast: IForecastDto): void
  resetForecast(): void
  getComparableCompanies(companyId: number): void
  setHistoricalPricesPeriod(chartPeriod: EChartPeriod): void
  getHistoricalCompanyPrices(companyId: number, chartPeriod: EChartPeriod): void
  getContourData(companyId: number): void
  getImpactIsolines(companyId: number): void

  getEpsPdf(companyId: number): void
  getRevPdf(companyId: number): void
  resetEpsPdf(): void
  resetRevPdf(): void
}

export const COMPANY_FACADE = new InjectionToken<ICompanyFacade>('COMPANY_FACADE')

@Injectable()
export class CompanyFacade implements ICompanyFacade {
  state$ = this.store$.select(selectCompanyState)
  inRequest$ = this.store$.select(selectCompanyInRequest)
  inError$ = this.store$.select(selectCompanyInError)
  error$ = this.store$.select(selectForecastListError)

  ticker$ = this.store$.select(selectTicker)
  isExistForecastInfo$ = this.store$.select(selectIsExistForecastInfo)
  isShowCancelOption$ = this.store$.select(selectIsShowCancelOption)
  forecast$ = this.store$.select(selectForecast)
  edgarHtml$ = this.store$.select(selectEdgarHtml)

  comparableCompanies$ = this.store$.select(selectComparableCompaniesCompanyPeers)
  comparableCompaniesError$ = this.store$.select(selectComparableCompaniesError)
  comparableCompaniesCompanyId$ = this.store$.select(selectComparableCompaniesCompanyId)
  comparableCompaniesInRequest$ = this.store$.select(selectComparableCompaniesInRequest)

  historicalCompanyPrices$ = this.store$.select(selectHistoricalCompanyPrices)
  historicalError$ = this.store$.select(selectHistoricalError)
  historicalChartPeriod$ = this.store$.select(selectHistoricalChartPeriod)
  historicalCompanyId$ = this.store$.select(selectHistoricalCompanyId)
  historicalInRequest$ = this.store$.select(selectHistoricalInRequest)

  contourData$ = this.store$.select(selectContourData)
  contourError$ = this.store$.select(selectContourError)
  contourCompanyId$ = this.store$.select(selectContourCompanyId)
  contourInRequest$ = this.store$.select(selectContourInRequest)

  impactIsolinesData$ = this.store$.select(selectImpactIsolinesData)
  impactIsolinesError$ = this.store$.select(selectImpactIsolinesError)
  impactIsolinesCompanyId$ = this.store$.select(selectImpactIsolinesCompanyId)
  impactIsolinesInRequest$ = this.store$.select(selectImpactIsolinesInRequest)

  epsPdfData$ = this.store$.select(selectEpsPdfData)
  epsPdfError$ = this.store$.select(selectEpsPdfError)
  epsPdfCompanyId$ = this.store$.select(selectEpsPdfCompanyId)
  epsPdfInRequest$ = this.store$.select(selectEpsPdfInRequest)

  revPdfData$ = this.store$.select(selectRevPdfData)
  revPdfError$ = this.store$.select(selectRevPdfError)
  revPdfCompanyId$ = this.store$.select(selectRevPdfCompanyId)
  revPdfInRequest$ = this.store$.select(selectRevPdfInRequest)

  companyInfoTab$ = this.store$.select(selectCompanyInfoTab)

  constructor(private store$: Store<IAppWithCompanyState>) {}

  initState(state: ICompanyState): void {
    this.store$.dispatch(COMPANY_ACTIONS.initState({ state }))
  }

  showCancelOption(isShowCancelOption: boolean): void {
    this.store$.dispatch(COMPANY_ACTIONS.showCancelOption({ isShowCancelOption }))
  }

  getForecast(ticker: string): void {
    this.store$.dispatch(COMPANY_ACTIONS.getForecast({ ticker }))
  }

  getForecastEdgar(ticker: string): void {
    this.store$.dispatch(COMPANY_ACTIONS.getForecastEdgar({ ticker }))
  }

  saveForecast(forecast: IForecastDto): void {
    this.store$.dispatch(COMPANY_ACTIONS.getForecastSuccess({ forecast }))
  }

  resetForecast(): void {
    this.store$.dispatch(COMPANY_ACTIONS.resetForecast())
  }

  getComparableCompanies(companyId: number): void {
    this.store$.dispatch(COMPANY_ACTIONS.getComparableCompanies({ companyId }))
  }

  setHistoricalPricesPeriod(chartPeriod: EChartPeriod): void {
    this.store$.dispatch(COMPANY_ACTIONS.setHistoricalPricesPeriod({ chartPeriod }))
  }

  getHistoricalCompanyPrices(companyId: number, chartPeriod: EChartPeriod): void {
    this.store$.dispatch(COMPANY_ACTIONS.getHistoricalPrices({ companyId, chartPeriod }))
  }

  getContourData(companyId: number): void {
    this.store$.dispatch(COMPANY_ACTIONS.getContourData({ companyId }))
  }

  getImpactIsolines(companyId: number): void {
    this.store$.dispatch(COMPANY_ACTIONS.getIsolineData({ companyId }))
  }

  getEpsPdf(companyId: number): void {
    this.store$.dispatch(COMPANY_ACTIONS.getEpsPdfData({ companyId }))
  }

  getRevPdf(companyId: number): void {
    this.store$.dispatch(COMPANY_ACTIONS.getRevPdfData({ companyId }))
  }

  resetEpsPdf(): void {
    this.store$.dispatch(COMPANY_ACTIONS.resetEpsPdfData())
  }

  resetRevPdf(): void {
    this.store$.dispatch(COMPANY_ACTIONS.resetRevPdfData())
  }
}
