import { Action, createReducer, on } from '@ngrx/store'
import _ from 'lodash-es'

import * as COMPANY_ACTIONS from './company.actions'
import { createCompanyInitState, ICompanyState } from './company.state'

export const companyState = createReducer(
  createCompanyInitState(),

  on(COMPANY_ACTIONS.initState, (state, { state: initState }) => ({ ...state, ...initState })),

  on(COMPANY_ACTIONS.getForecast, state => ({ ...state, inRequest: true })),
  on(COMPANY_ACTIONS.getForecastSuccess, (state, { forecast }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      ticker: forecast.company.ticker,
      isExistForecastInfo: !_.isNil(forecast.epsForecastProximilar),
      forecast,
      error: null,
    },
  })),
  on(COMPANY_ACTIONS.getForecastError, (state, { ticker, error }) => ({
    ...state,
    inRequest: false,
    inError: true,
    data: {
      ...state.data,
      ticker,
      forecast: null,
      isExistForecastInfo: false,
      error,
    },
  })),

  // TODO: Tmp
  on(COMPANY_ACTIONS.getForecastEdgar, state => ({ ...state, inRequest: true })),
  on(COMPANY_ACTIONS.getForecastEdgarSuccess, (state, { edgarHtml }) => ({
    ...state,
    inRequest: false,
    inError: false,
    data: {
      ...state.data,
      edgarHtml,
    },
  })),
  on(COMPANY_ACTIONS.getForecastEdgarError, state => ({ ...state, inRequest: false })),

  on(COMPANY_ACTIONS.showCancelOption, (state, { isShowCancelOption }) => ({
    ...state,
    data: {
      ...state.data,
      isShowCancelOption,
    },
  })),

  on(COMPANY_ACTIONS.resetForecast, () => createCompanyInitState()),

  // Comparable Companies

  on(COMPANY_ACTIONS.getComparableCompanies, state => ({
    ...state,
    data: {
      ...state.data,
      comparableCompanies: {
        ...state.data.comparableCompanies,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getComparableCompaniesSuccess, (state, { companyId, companyPeers }) => ({
    ...state,
    data: {
      ...state.data,
      comparableCompanies: {
        inRequest: false,
        companyId,
        companyPeers,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getComparableCompaniesError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      comparableCompanies: {
        inRequest: false,
        companyId,
        companyPeers: [],
        error,
      },
    },
  })),

  // Historical

  on(COMPANY_ACTIONS.getHistoricalPrices, state => ({
    ...state,
    data: {
      ...state.data,
      historical: {
        ...state.data.historical,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getHistoricalPricesSuccess, (state, { companyId, companyPrices, chartPeriod }) => ({
    ...state,
    data: {
      ...state.data,
      historical: {
        inRequest: false,
        chartPeriod,
        companyId,
        companyPrices,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getHistoricalPricesError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      historical: {
        inRequest: false,
        companyId,
        companyPrices: [],
        error,
      },
    },
  })),

  // Contour

  on(COMPANY_ACTIONS.getContourData, state => ({
    ...state,
    data: {
      ...state.data,
      contour: {
        ...state.data.contour,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getContourDataSuccess, (state, { companyId, contourData }) => ({
    ...state,
    data: {
      ...state.data,
      contour: {
        inRequest: false,
        companyId,
        contourData,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getContourDataError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      contour: {
        inRequest: false,
        companyId,
        contourData: [],
        error,
      },
    },
  })),

  // Impact Isolines

  on(COMPANY_ACTIONS.getIsolineData, state => ({
    ...state,
    data: {
      ...state.data,
      impactIsolines: {
        ...state.data.impactIsolines,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getIsolineDataSuccess, (state, { companyId, isolineData }) => ({
    ...state,
    data: {
      ...state.data,
      impactIsolines: {
        inRequest: false,
        companyId,
        isolineData,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getIsolineDataError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      impactIsolines: {
        inRequest: false,
        companyId,
        isolineData: [],
        error,
      },
    },
  })),

  // Eps Pdf

  on(COMPANY_ACTIONS.getEpsPdfData, state => ({
    ...state,
    data: {
      ...state.data,
      epsPdf: {
        ...state.data.epsPdf,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getEpsPdfDataSuccess, (state, { companyId, data }) => ({
    ...state,
    data: {
      ...state.data,
      epsPdf: {
        inRequest: false,
        companyId,
        data,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getEpsPdfDataError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      epsPdf: {
        inRequest: false,
        companyId,
        data: [],
        error,
      },
    },
  })),

  on(COMPANY_ACTIONS.resetEpsPdfData, state => ({
    ...state,
    data: {
      ...state.data,
      epsPdf: {},
    },
  })),

  // Rev Pdf

  on(COMPANY_ACTIONS.getRevPdfData, state => ({
    ...state,
    data: {
      ...state.data,
      revPdf: {
        ...state.data.revPdf,
        inRequest: true,
      },
    },
  })),
  on(COMPANY_ACTIONS.getRevPdfDataSuccess, (state, { companyId, data }) => ({
    ...state,
    data: {
      ...state.data,
      revPdf: {
        inRequest: false,
        companyId,
        data,
        error: null,
      },
    },
  })),
  on(COMPANY_ACTIONS.getRevPdfDataError, (state, { companyId, error }) => ({
    ...state,
    data: {
      ...state.data,
      revPdf: {
        inRequest: false,
        companyId,
        data: [],
        error,
      },
    },
  })),

  on(COMPANY_ACTIONS.resetRevPdfData, state => ({
    ...state,
    data: {
      ...state.data,
      revPdf: {},
    },
  }))
)

export const companyReducer = (state: ICompanyState, action: Action): ICompanyState => companyState(state, action)
