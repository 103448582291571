import { Action, createReducer, on } from '@ngrx/store'

import * as UI_ACTIONS from './ui.actions'
import { createUiInitState, IUiState } from './ui.state'

export const uiState = createReducer(
  createUiInitState(),

  on(UI_ACTIONS.saveThemeMode, (state, { isDarkTheme }) => ({ ...state, isDarkTheme })),
  on(UI_ACTIONS.saveLanguage, (state, { language }) => ({ ...state, language })),

  on(UI_ACTIONS.startRequest, state => ({ ...state, inRequestQueue: state.inRequestQueue + 1 })),
  on(UI_ACTIONS.endRequest, state => ({ ...state, inRequestQueue: state.inRequestQueue - 1 }))
)

export const uiReducer = (state: IUiState, action: Action): IUiState => uiState(state, action)
