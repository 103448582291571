import { createFeatureSelector, createSelector } from '@ngrx/store'

import { getMergedRoute } from '../router/router.selectors'

import { FORECAST_LIST_DATA_TAB_NAME } from './forecast-list.model'
import { forecastListFeatureKey, IForecastListState } from './forecast-list.state'

export const selectForecastListState = createFeatureSelector<IForecastListState>(forecastListFeatureKey)

export const selectForecastListInRequest = createSelector([selectForecastListState], state => state.inRequest)

export const selectForecastListInError = createSelector([selectForecastListState], state => state.inError)

export const selectForecastListData = createSelector([selectForecastListState], state => state.data)

export const selectIsFavorite = createSelector([selectForecastListData], state => state.isFavorite)

export const selectUserFolderId = createSelector([selectForecastListData], state => state.userFolderId)

export const selectForecasts = createSelector([selectForecastListData], state => state.forecasts)

export const selectForecastByTicker = (ticker: string): any =>
  createSelector([selectForecastListData], state => state.forecasts.find(f => f.company.ticker === ticker))

export const selectSelectedTicker = createSelector([selectForecastListData], state => state.selectedTicker)

export const selectForecastListSearchData = createSelector([selectForecastListData], state => state.searchForecastData)

export const selectForecastListPaginator = createSelector([selectForecastListData], state => state.paginator)

export const selectSearchForecastListData = createSelector([selectForecastListData], state => state.searchForecastData)

export const selectFilterValuesForecastListData = createSelector(
  [selectForecastListData],
  state => state.filterValuesForecastData
)

export const selectForecastListError = createSelector([selectForecastListData], state => state.error)

// TODO: Refactor
export const selectSelectedTab = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[FORECAST_LIST_DATA_TAB_NAME]
)

// TODO: Refactor
export const selectSelectedTicker2 = createSelector([getMergedRoute], mergedRoute => mergedRoute?.params['ticker'])

// TODO: Refactor
export const selectUserFolderId2 = createSelector([getMergedRoute], mergedRoute =>
  mergedRoute?.params['userFolderId'] ? +mergedRoute.params['userFolderId'] : null
)
