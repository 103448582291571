import { Inject, Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'

import { Actions, createEffect, ofType } from '@ngrx/effects'
import { catchError, map, mergeMap, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators'

import { ForecastApiService } from '~/core/api'
import { FORECAST_LIST_PATH } from '~const/index'

import { FORECAST_LIST_FACADE, IForecastListFacade } from '../forecast-list/forecast-list.facade'

import * as SEARCH_FORECAST_ACTIONS from './search-forecast.actions'
import { ISearchForecastFacade, SEARCH_FORECAST_FACADE } from './search-forecast.facade'

@Injectable()
export class SearchForecastEffects {
  constructor(
    private router: Router,
    private actions$: Actions,
    private route: ActivatedRoute,
    private forecastApiService: ForecastApiService,
    @Inject(FORECAST_LIST_FACADE) private forecastListFacade: IForecastListFacade,
    @Inject(SEARCH_FORECAST_FACADE) private searchForecastFacade: ISearchForecastFacade
  ) {}

  searchPreview$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_FORECAST_ACTIONS.searchPreview),
      withLatestFrom(
        this.searchForecastFacade.inCurrentFolder$,
        this.forecastListFacade.isFavorite$,
        this.forecastListFacade.userFolderId$
      ),
      map(([{ search }, inCurrentFolder, isFavorite, userFolderId]) => ({
        search,
        isFavorite: inCurrentFolder && isFavorite,
        userFolderId: inCurrentFolder ? userFolderId : null,
      })),
      switchMap(({ search, isFavorite, userFolderId }) =>
        this.forecastApiService.getSearchPreviewForecasts(search, isFavorite, userFolderId).pipe(
          takeUntil(this.actions$.pipe(ofType(SEARCH_FORECAST_ACTIONS.clearSearchData))),
          takeUntil(this.actions$.pipe(ofType(SEARCH_FORECAST_ACTIONS.clearSearchCompanyPreview))),
          map(searchOptions => SEARCH_FORECAST_ACTIONS.searchPreviewSuccess({ searchOptions })),
          catchError(error => [SEARCH_FORECAST_ACTIONS.searchPreviewError({ error })])
        )
      )
    )
  )

  resetSearch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_FORECAST_ACTIONS.resetSearch),
      mergeMap(() => [
        SEARCH_FORECAST_ACTIONS.clearSearchData(),
        SEARCH_FORECAST_ACTIONS.updateSearchParams({ search: '', selectedTicker: '' }),
      ])
    )
  )

  searchForecasts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_FORECAST_ACTIONS.searchForecasts),
      withLatestFrom(this.searchForecastFacade.shouldRedirectToForecastTable$),
      mergeMap(([{ search }, shouldRedirect]) => [
        shouldRedirect
          ? SEARCH_FORECAST_ACTIONS.redirectToSearchResultPage({ search, selectedTicker: '' })
          : SEARCH_FORECAST_ACTIONS.updateSearchParams({ search, selectedTicker: '' }),
        SEARCH_FORECAST_ACTIONS.clearSearchCompanyPreview(),
      ])
    )
  )

  chooseCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SEARCH_FORECAST_ACTIONS.chooseCompany),
      withLatestFrom(this.searchForecastFacade.shouldRedirectToForecastTable$),
      map(([{ selectedTicker }, shouldRedirect]) =>
        shouldRedirect
          ? SEARCH_FORECAST_ACTIONS.redirectToSearchResultPage({ search: selectedTicker, selectedTicker })
          : SEARCH_FORECAST_ACTIONS.updateSearchParams({ search: selectedTicker, selectedTicker })
      )
    )
  )

  updateSearchParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SEARCH_FORECAST_ACTIONS.updateSearchParams),
        tap(({ search, selectedTicker }) => this.setSearchParams(search, selectedTicker))
      ),
    {
      dispatch: false,
    }
  )

  redirectToSearchResultPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SEARCH_FORECAST_ACTIONS.redirectToSearchResultPage),
        tap(({ search, selectedTicker }) =>
          selectedTicker ? this.redirectToCompanyPage(selectedTicker) : this.redirectToForecastTable(search)
        )
      ),
    {
      dispatch: false,
    }
  )

  private setSearchParams(search = '', selectedTicker = ''): void {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { search, selectedTicker },
      queryParamsHandling: 'merge',
    })
  }

  private redirectToForecastTable(search: string): void {
    this.router.navigate(['/', FORECAST_LIST_PATH], {
      queryParams: { search, selectedTicker: '' },
      queryParamsHandling: 'merge',
    })
  }

  private redirectToCompanyPage(selectedTicker: string): void {
    this.router.navigate(['/', selectedTicker])
  }
}
