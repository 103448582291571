import { createFeatureSelector, createSelector } from '@ngrx/store'

import { getMergedRoute } from '../router/router.selectors'

import { COMPANY_INFO_DATA_TAB_NAME } from './company.model'
import { companyFeatureKey, ICompanyState } from './company.state'

export const selectCompanyState = createFeatureSelector<ICompanyState>(companyFeatureKey)

export const selectCompanyInRequest = createSelector([selectCompanyState], state => state.inRequest)

export const selectCompanyInError = createSelector([selectCompanyState], state => state.inError)

export const selectCompanyData = createSelector([selectCompanyState], state => state.data)

export const selectForecastListError = createSelector([selectCompanyData], state => state.error)

export const selectForecast = createSelector([selectCompanyData], state => state.forecast)

export const selectEdgarHtml = createSelector([selectCompanyData], state => state.edgarHtml || '')

export const selectTicker = createSelector([selectCompanyData], state => state.ticker)

export const selectIsExistForecastInfo = createSelector([selectCompanyData], state => state.isExistForecastInfo)

export const selectIsShowCancelOption = createSelector([selectCompanyData], state => state.isShowCancelOption)

// Comparable Companies

export const selectComparableCompanies = createSelector([selectCompanyData], state => state.comparableCompanies)

export const selectComparableCompaniesInRequest = createSelector([selectComparableCompanies], state => state?.inRequest)

export const selectComparableCompaniesCompanyPeers = createSelector(
  [selectComparableCompanies],
  state => state?.companyPeers
)

export const selectComparableCompaniesCompanyId = createSelector([selectComparableCompanies], state => state?.companyId)

export const selectComparableCompaniesError = createSelector([selectComparableCompanies], state => state?.error)

// Historical

export const selectHistorical = createSelector([selectCompanyData], state => state.historical)

export const selectHistoricalInRequest = createSelector([selectHistorical], state => state?.inRequest)

export const selectHistoricalCompanyPrices = createSelector([selectHistorical], state => state?.companyPrices)

export const selectHistoricalChartPeriod = createSelector([selectHistorical], state => state?.chartPeriod)

export const selectHistoricalCompanyId = createSelector([selectHistorical], state => state?.companyId)

export const selectHistoricalError = createSelector([selectHistorical], state => state?.error)

// Contour

export const selectContour = createSelector([selectCompanyData], state => state.contour)

export const selectContourInRequest = createSelector([selectContour], state => state?.inRequest)

export const selectContourData = createSelector([selectContour], state => state?.contourData)

export const selectContourCompanyId = createSelector([selectContour], state => state?.companyId)

export const selectContourError = createSelector([selectContour], state => state?.error)

// Impact Isolines

export const selectImpactIsolines = createSelector([selectCompanyData], state => state.impactIsolines)

export const selectImpactIsolinesInRequest = createSelector([selectImpactIsolines], state => state?.inRequest)

export const selectImpactIsolinesData = createSelector([selectImpactIsolines], state => state?.isolineData)

export const selectImpactIsolinesCompanyId = createSelector([selectImpactIsolines], state => state?.companyId)

export const selectImpactIsolinesError = createSelector([selectImpactIsolines], state => state?.error)

// Eps Pdf

export const selectEpsPdf = createSelector([selectCompanyData], state => state.epsPdf)

export const selectEpsPdfInRequest = createSelector([selectEpsPdf], state => state?.inRequest)

export const selectEpsPdfData = createSelector([selectEpsPdf], state => state?.data)

export const selectEpsPdfCompanyId = createSelector([selectEpsPdf], state => state?.companyId)

export const selectEpsPdfError = createSelector([selectEpsPdf], state => state?.error)

// Rev Pdf

export const selectRevPdf = createSelector([selectCompanyData], state => state.revPdf)

export const selectRevPdfInRequest = createSelector([selectRevPdf], state => state?.inRequest)

export const selectRevPdfData = createSelector([selectRevPdf], state => state?.data)

export const selectRevPdfCompanyId = createSelector([selectRevPdf], state => state?.companyId)

export const selectRevPdfError = createSelector([selectRevPdf], state => state?.error)

// TODO: Refactor
export const selectCompanyInfoTab = createSelector(
  [getMergedRoute],
  mergedRoute => mergedRoute?.data[COMPANY_INFO_DATA_TAB_NAME]
)
