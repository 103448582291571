import { NgModule } from '@angular/core'

import { routerReducer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreModule } from '@ngrx/store'

import { MergedRouterStateSerializer } from './route-serialzer'
import { ROUTER_FACADE, RouterFacade } from './router.facade'
import { routerFeatureKey } from './router.state'

@NgModule({
  imports: [
    StoreModule.forFeature(routerFeatureKey, routerReducer),
    StoreRouterConnectingModule.forRoot({ stateKey: routerFeatureKey }),
  ],
  providers: [
    {
      provide: RouterStateSerializer,
      useClass: MergedRouterStateSerializer,
    },
    { provide: ROUTER_FACADE, useClass: RouterFacade },
  ],
})
export class RouterFeatureModule {}
