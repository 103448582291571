import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { ForecastListEffects } from './company.effects'
import { COMPANY_FACADE, CompanyFacade } from './company.facade'
import { companyReducer } from './company.reducer'
import { companyFeatureKey } from './company.state'

const EFFECTS = [ForecastListEffects]

@NgModule({
  imports: [StoreModule.forFeature(companyFeatureKey, companyReducer), EffectsModule.forFeature(EFFECTS)],
  providers: [...EFFECTS, { provide: COMPANY_FACADE, useClass: CompanyFacade }],
})
export class CompanyModule {}
