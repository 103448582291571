import { createFeatureSelector, createSelector } from '@ngrx/store'

import { IUiState, uiFeatureKey } from './ui.state'

export const selectUi = createFeatureSelector<IUiState>(uiFeatureKey)

export const selectUiThemeMode = createSelector([selectUi], state => state.isDarkTheme)

export const selectInRequest = createSelector([selectUi], state => Boolean(state.inRequestQueue))

export const selectUiLanguage = createSelector([selectUi], state => state.language)
