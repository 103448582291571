import { Injectable, InjectionToken } from '@angular/core'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { IUserDto, IUserFolderDto } from '~/core/models'

import * as USER_ACTIONS from './user.actions'
import {
  selectPermissionSectionEntities,
  selectPermissionSections,
  selectUser,
  selectUserError,
  selectUserFolders,
  selectUserInError,
  selectUserInRequest,
  selectUserState,
} from './user.selectors'
import { IAppWithUserState, IUserState } from './user.state'

export interface IUserFacade {
  state$: Observable<IUserState>
  inRequest$: Observable<boolean>
  inError$: Observable<boolean>
  user$: Observable<IUserDto | null | undefined>
  permissionSectionEntities$: Observable<
    { [key: string]: { [key: string]: { [key: string]: boolean } } } | null | undefined
  >
  permissionSections$: Observable<{ [key: string]: boolean | undefined } | null | undefined>
  userFolders$: Observable<IUserFolderDto[] | undefined>
  error$: Observable<Error | null | undefined>

  initState(state: IUserState): void

  initAuthUser(): void
  updateCurrentUser(firstName: string, lastName: string, companyName: string): void
  logout(): void
  addUserFolder(folderName: string): void
  editUserFolder(folderId: number, folderName: string): void
  deleteUserFolder(folderId: number): void
  moveForecastsToUserFolder(folderId: number, companyIds: number[]): void
}

export const USER_FACADE = new InjectionToken<IUserFacade>('USER_FACADE')

@Injectable()
export class UserFacade implements IUserFacade {
  state$ = this.store$.select(selectUserState)

  inRequest$ = this.store$.select(selectUserInRequest)
  inError$ = this.store$.select(selectUserInError)
  error$ = this.store$.select(selectUserError)
  user$ = this.store$.select(selectUser)
  permissionSectionEntities$ = this.store$.select(selectPermissionSectionEntities)
  permissionSections$ = this.store$.select(selectPermissionSections)
  userFolders$ = this.store$.select(selectUserFolders)

  constructor(private store$: Store<IAppWithUserState>) {}

  initState(state: IUserState): void {
    this.store$.dispatch(USER_ACTIONS.initState({ state }))
  }

  initAuthUser(): void {
    this.store$.dispatch(USER_ACTIONS.initAuthUser())
  }

  updateCurrentUser(firstName: string, lastName: string, companyName: string): void {
    this.store$.dispatch(USER_ACTIONS.updateCurrentUser({ firstName, lastName, companyName }))
  }

  logout(): void {
    this.store$.dispatch(USER_ACTIONS.logout())
  }

  addUserFolder(folderName: string): void {
    this.store$.dispatch(USER_ACTIONS.addUserFolder({ folderName }))
  }

  editUserFolder(folderId: number, folderName: string): void {
    this.store$.dispatch(USER_ACTIONS.editUserFolder({ folderId, folderName }))
  }

  deleteUserFolder(folderId: number): void {
    this.store$.dispatch(USER_ACTIONS.deleteUserFolder({ folderId }))
  }

  moveForecastsToUserFolder(folderId: number, companyIds: number[]): void {
    this.store$.dispatch(USER_ACTIONS.moveForecastsToUserFolder({ folderId, companyIds }))
  }
}
