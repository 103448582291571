export enum EQuarterlyDashboardSummaryFlag {
  CASUAL_COLUMN = 'N',
  SUMMARY_COLUMN = 'Y',
}

export interface IQuarterlyDashboardCore {
  id: number
  year: number
  quarter: number
  summaryFlag: string
  reportedCount: number
  hedgedTotretPostimpact1: number
  // Vs. Wall Street Consensu
  epsEstimateBeatPercent: number
  revEstimateBeatPercent: number
  epsEstimateBeatHtp1: number
  epsEstimateMissHtp1: number
  revEstimateBeatHtp1: number
  revEstimateMissHtp1: number
  // Vs. Proximilar's AI Forecast
  epsForecastBeatPercent: number
  revForecastBeatPercent: number
  epsForecastBeatHtp1: number
  epsForecastMissHtp1: number
  revForecastBeatHtp1: number
  revForecastMissHtp1: number
}

export interface IQuarterlyDashboardQuarter {
  id: number
  year: number
  quarter: number
  reportedCount: number
  // Vs. Wall Street Consensus
  epsEstimateBeatPercent: number
  revEstimateBeatPercent: number
  epsEstimateBeatHtp1: number
  epsEstimateMissHtp1: number
  revEstimateBeatHtp1: number
  revEstimateMissHtp1: number
  // Vs. Proximilar's AI Forecast
  epsForecastBeatPercent: number
  revForecastBeatPercent: number
  epsForecastBeatHtp1: number
  epsForecastMissHtp1: number
  revForecastBeatHtp1: number
  revForecastMissHtp1: number
}

export interface IQuarterlyDashboardMktCap extends IQuarterlyDashboardCore {
  mktCapName: string
  mktCapFrom: number
  mktCapTo: number
}

export interface IQuarterlyDashboardSector extends IQuarterlyDashboardCore {
  sector: string
}

export interface IQuarterlyDashboardSectorTop500 extends IQuarterlyDashboardCore {
  sector: string
}

export enum EQuarterlyDashboardPath {
  MARKET_CAP = 'market-cap',
  SECTOR = 'sector',
  SECTOR_TOP500 = 'sector-top500',
}

export enum EQuarterlyDashboardViewMode {
  WALL_STREET_CONSENSUS = 'wallStreetConsensus',
  PROXIMILAR_AI_FORECAST = 'proximilarAIForecast',
}

export interface IQuarterlyDashboardRate {
  year: number
  quarter: number
  eps: number
  rev: number
}

export interface IQuarterlyDashboardReaction {
  year: number
  quarter: number
  epsBeat: number
  epsMiss: number
  revBeat: number
  revMiss: number
}
