import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { MatSnackBarModule } from '@angular/material/snack-bar'

import { TranslateModule } from '@ngx-translate/core'

import { SnackBarComponent } from './snack-bar.component'
import { SnackBarService } from './snack-bar.service'

@NgModule({
  imports: [CommonModule, MatSnackBarModule, TranslateModule, SnackBarComponent],
  providers: [SnackBarService],
})
export class SnackBarModule {}
