import { createAction, props } from '@ngrx/store'

import {
  ICompanyDto,
  ICompanyPriceDto,
  IContourEpsDto,
  IEpsPdfDto,
  IForecastDto,
  IIsolineEpsDto,
  IRevPdfDto,
} from '~/core/models'
import { EChartPeriod } from '~/core/models'

import { companyFeatureKey, ICompanyState } from './company.state'

export const initState = createAction(`[${companyFeatureKey}] Init state`, props<{ state: ICompanyState }>())

export const getForecast = createAction(`[${companyFeatureKey}] Get Forecast`, props<{ ticker: string }>())

export const getForecastSuccess = createAction(
  `[${companyFeatureKey}] Get Forecast Success`,
  props<{ forecast: IForecastDto }>()
)

export const getForecastError = createAction(
  `[${companyFeatureKey}] Get Forecast Error`,
  props<{ ticker: string; error: Error }>()
)

export const getForecastEdgar = createAction(`[${companyFeatureKey}] Get Forecast Edgar`, props<{ ticker: string }>())

export const getForecastEdgarSuccess = createAction(
  `[${companyFeatureKey}] Get Forecast Edgar Success`,
  props<{ edgarHtml: string }>()
)

export const getForecastEdgarError = createAction(
  `[${companyFeatureKey}] Get Forecast Edgar Error`,
  props<{ ticker: string; error: Error }>()
)

export const showCancelOption = createAction(
  `[${companyFeatureKey}] Show Cancel Option`,
  props<{ isShowCancelOption: boolean }>()
)

export const resetForecast = createAction(`[${companyFeatureKey}] Reset Forecast`)

// Comparable Companies

export const getComparableCompanies = createAction(
  `[${companyFeatureKey}] Get Comparable Companies`,
  props<{ companyId: number }>()
)

export const getComparableCompaniesSuccess = createAction(
  `[${companyFeatureKey}] Get Comparable Companies Success`,
  props<{ companyId: number; companyPeers: ICompanyDto[] }>()
)

export const getComparableCompaniesError = createAction(
  `[${companyFeatureKey}] Get Comparable Companies Error`,
  props<{ companyId: number; error: Error }>()
)

// Historical

export const setHistoricalPricesPeriod = createAction(
  `[${companyFeatureKey}] Set Historical Prices Period`,
  props<{ chartPeriod: EChartPeriod }>()
)

export const getHistoricalPrices = createAction(
  `[${companyFeatureKey}] Get Historical Prices`,
  props<{ companyId: number; chartPeriod: EChartPeriod }>()
)

export const getHistoricalPricesSuccess = createAction(
  `[${companyFeatureKey}] Get Historical Prices Success`,
  props<{ companyId: number; companyPrices: ICompanyPriceDto[]; chartPeriod: EChartPeriod }>()
)

export const getHistoricalPricesError = createAction(
  `[${companyFeatureKey}] Get Historical Prices Error`,
  props<{ companyId: number; error: Error }>()
)

// Contour

export const getContourData = createAction(`[${companyFeatureKey}] Get Contour Data`, props<{ companyId: number }>())

export const getContourDataSuccess = createAction(
  `[${companyFeatureKey}] Get Contour Data Success`,
  props<{ companyId: number; contourData: IContourEpsDto[] }>()
)

export const getContourDataError = createAction(
  `[${companyFeatureKey}] Get Contour Data Error`,
  props<{ companyId: number; error: Error }>()
)

// Impact Isolines

export const getIsolineData = createAction(`[${companyFeatureKey}] Get Isolines Data`, props<{ companyId: number }>())

export const getIsolineDataSuccess = createAction(
  `[${companyFeatureKey}] Get Isolines Data Success`,
  props<{ companyId: number; isolineData: IIsolineEpsDto[] }>()
)

export const getIsolineDataError = createAction(
  `[${companyFeatureKey}] Get Isolines Data Error`,
  props<{ companyId: number; error: Error }>()
)

// Eps Pdf

export const getEpsPdfData = createAction(`[${companyFeatureKey}] Get Eps Pdf Data`, props<{ companyId: number }>())

export const getEpsPdfDataSuccess = createAction(
  `[${companyFeatureKey}] Get Eps Pdf Data Success`,
  props<{ companyId: number; data: IEpsPdfDto[] }>()
)

export const getEpsPdfDataError = createAction(
  `[${companyFeatureKey}] Get Eps Pdf Data Error`,
  props<{ companyId: number; error: Error }>()
)

export const resetEpsPdfData = createAction(`[${companyFeatureKey}] Reset Eps Pdf Data`)

// Rev Pdf

export const getRevPdfData = createAction(`[${companyFeatureKey}] Get Rev Pdf Data`, props<{ companyId: number }>())

export const getRevPdfDataSuccess = createAction(
  `[${companyFeatureKey}] Get Rev Pdf Data Success`,
  props<{ companyId: number; data: IRevPdfDto[] }>()
)

export const getRevPdfDataError = createAction(
  `[${companyFeatureKey}] Get Rev Pdf Data Error`,
  props<{ companyId: number; error: Error }>()
)

export const resetRevPdfData = createAction(`[${companyFeatureKey}] Reset Rev Pdf Data`)
