import { createAction, props } from '@ngrx/store'

import { IForecastSearchPreviewDto } from '~/core/models'

import { searchForecastFeatureKey } from './search-forecast.state'

export const searchPreview = createAction(`[${searchForecastFeatureKey}] Search Preview`, props<{ search: string }>())

export const searchPreviewSuccess = createAction(
  `[${searchForecastFeatureKey}] Search Preview Success`,
  props<{ searchOptions: IForecastSearchPreviewDto[] }>()
)

export const searchPreviewError = createAction(
  `[${searchForecastFeatureKey}] Search Preview Error`,
  props<{ error: Error }>()
)

export const searchForecasts = createAction(
  `[${searchForecastFeatureKey}] Search Forecasts`,
  props<{ search: string }>()
)

export const updateSearchParams = createAction(
  `[${searchForecastFeatureKey}] Update Search Params`,
  props<{ search: string; selectedTicker: string }>()
)

export const resetSearch = createAction(`[${searchForecastFeatureKey}] Reset Search`)

export const clearSearchData = createAction(`[${searchForecastFeatureKey}] Clear Search Data`)

export const clearSearchCompanyPreview = createAction(`[${searchForecastFeatureKey}] Clear Search Company preview`)

export const chooseCompany = createAction(
  `[${searchForecastFeatureKey}] Choose Company`,
  props<{ selectedTicker: string }>()
)

export const redirectToSearchResultPage = createAction(
  `[${searchForecastFeatureKey}] Redirect To Search Result Page`,
  props<{ search: string; selectedTicker: string }>()
)

export const changeCurrentFolderOption = createAction(
  `[${searchForecastFeatureKey}] Change Current Folder Option`,
  props<{ inCurrentFolder: boolean }>()
)

export const allowSearchInCurrentFolder = createAction(
  `[${searchForecastFeatureKey}] Change Allow Search In Current Folder Option`,
  props<{ allowSearchInCurrentFolder: boolean }>()
)
