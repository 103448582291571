import { NgModule } from '@angular/core'

import { EffectsModule } from '@ngrx/effects'
import { StoreModule } from '@ngrx/store'

import { AdminFeatureModule } from './admin'
import { CompanyModule } from './company'
import { ForecastListModule } from './forecast-list'
import { PerformanceModule } from './performance'
import { QuarterlyDashboardModule } from './quarterly-dashboard'
import { RouterFeatureModule } from './router'
import { SearchForecastModule } from './search-forecast'
import { UiModule } from './ui'
import { UserModule } from './user'

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    StoreModule.forRoot([]),
    RouterFeatureModule,
    ForecastListModule,
    SearchForecastModule,
    AdminFeatureModule,
    PerformanceModule,
    QuarterlyDashboardModule,
    CompanyModule,
    UserModule,
    UiModule,
  ],
})
export class AppStoreModule {}
