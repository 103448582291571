import { QUARTERLY_DASHBOARD_DEFAULT_VIEW_MODE } from './quarterly-dashboard.constant'
import {
  EQuarterlyDashboardViewMode,
  IQuarterlyDashboardMktCap,
  IQuarterlyDashboardQuarter,
  IQuarterlyDashboardSector,
  IQuarterlyDashboardSectorTop500,
} from './quarterly-dashboard.model'

export const quarterlyDashboardFeatureKey = 'QuarterlyDashboard prox'

export interface IQuarterlyDashboardState {
  inRequest: boolean
  inError: boolean
  data: {
    quarterList: IQuarterlyDashboardQuarter[]
    quarter?: IQuarterlyDashboardQuarter | null
    defaultQuarter?: IQuarterlyDashboardQuarter | null
    error?: Error | null
    quarterlyDashboardMktCap?: {
      inRequest?: boolean
      quarter?: IQuarterlyDashboardQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IQuarterlyDashboardMktCap[]
      error?: Error | null
    }
    quarterlyDashboardSector?: {
      inRequest?: boolean
      quarter?: IQuarterlyDashboardQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IQuarterlyDashboardSector[]
      error?: Error | null
    }
    quarterlyDashboardSectorTop500?: {
      inRequest?: boolean
      quarter?: IQuarterlyDashboardQuarter
      sortBy?: string
      order?: 'asc' | 'desc' | ''
      data?: IQuarterlyDashboardSectorTop500[]
      error?: Error | null
    }
    quarterlyDashboardViewMode: EQuarterlyDashboardViewMode
  }
}

export const createQuarterlyDashboardInitState = (): IQuarterlyDashboardState => ({
  inRequest: false,
  inError: false,
  data: {
    quarterList: [],
    quarterlyDashboardViewMode: QUARTERLY_DASHBOARD_DEFAULT_VIEW_MODE,
  },
})

export interface IAppWithQuarterlyDashboardState {
  quarterlyDashboard: IQuarterlyDashboardState
}

export const createAppWithQuarterlyDashboardInitState = (): IAppWithQuarterlyDashboardState => ({
  quarterlyDashboard: createQuarterlyDashboardInitState(),
})
