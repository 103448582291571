import { Injectable } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'

import { APP } from '~const/index'

import { SnackBarComponent } from './snack-bar.component'
import { SNACK_BAR_DEFAULT } from './snack-bar.constant'
import { ISnackBar } from './snack-bar.model'

@Injectable()
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(snackBarData: ISnackBar): void {
    const data = { ...SNACK_BAR_DEFAULT, ...snackBarData }

    this.snackBar.openFromComponent(SnackBarComponent, {
      duration: (data.duration || APP.NOTIFICATION_DURATION) * 1000,
      data,
    })
  }
}
