import { Injectable, InjectionToken } from '@angular/core'
import { Data } from '@angular/router'

import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'

import { EGuardSectionName } from '~/core/models'

import { selectData, selectDataByKey, selectGuardSectionName, selectIsCompanyPage } from './router.selectors'
import { RouteReducerState } from './router.state'

export interface IRouterFacade {
  guardSectionName$: Observable<EGuardSectionName | undefined>
  isCompanyPage$: Observable<boolean>
  data$: Observable<Data>

  selectDataByKey(key: string): Observable<any>
}

export const ROUTER_FACADE = new InjectionToken<IRouterFacade>('ROUTER_FACADE')

@Injectable()
export class RouterFacade implements IRouterFacade {
  guardSectionName$ = this.store$.select(selectGuardSectionName)
  isCompanyPage$ = this.store$.select(selectIsCompanyPage)
  data$ = this.store$.select(selectData)

  constructor(private store$: Store<RouteReducerState>) {}

  selectDataByKey(key: string): Observable<any> {
    return this.store$.select(selectDataByKey(key))
  }
}
