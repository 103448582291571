import { Data, Params } from '@angular/router'

import { RouterReducerState } from '@ngrx/router-store'

export const routerFeatureKey = 'Router prox'

export interface IRoute {
  url: string
  queryParams: Params
  params: Params
  data: Data
}

export type RouteReducerState = RouterReducerState<IRoute>
